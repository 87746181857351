import React, {Component} from 'react';
import moment from 'moment-timezone';
import {CheckCircle, Info, RefreshCw, XCircle} from "react-feather";
import Modal from "../../components/modal";
import Access from "../../components/accessManager";
import {Link} from "react-router-dom";

var STARTING_INDEX = 0
const WHITELISTED_MEMBER_COLUMNS = ["msisdn", "member_id", "name", "eligible_amount"]

class MemberAccount extends Component {
    state = {
        moreDetailsModal: false,
        data: {},
        moreDetailsData: {},
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        default_data: {
            msisdn: "2547",
            member_id: "",
            name: "",
            eligible_amount: 0
        },
        editModal: false,
        editSendRequest: false,
        update_fields: {errors: {}},
        member_account: {}


    };
    timeout = null;

    render() {
        return (
            <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className='card'>
                    <table className='table table-striped table-hover'>
                        <thead>
                        <th>Member Account</th>
                        <th>
                            <Access permission='MEMBER_ACCOUNT_CRUD'>
                                <button
                                    onClick={() => {
                                        this.setState({
                                            editModal: true,
                                            update_fields: {errors: {}, ...(this.state.data?.id ? this.state.data : this.state.default_data)},
                                            member_account: this.state.data?.id ? this.state.data : this.state.default_data
                                        });
                                    }}
                                    className='no-wrap pr-3 d-md-flex my-2 btn btn-primary '>
                                    <span className='pl-1 font-weight-bold'> Edit Member Account  </span>
                                </button>
                            </Access>

                        </th>
                        </thead>

                        {this.state.data && Object.keys(this.state.data).slice(0, Math.ceil(Object.keys(this.state.data).length / 1)).map((d, i) => {
                            return (
                                <tr key={i}>
                                    <td className='text-capitalize'>
                                        {d.replace(/\_/g, ' ')}
                                    </td>
                                    <td>{this.state.data[d] ? this.state.data[d] : '-'}</td>
                                </tr>
                            );
                        })}

                    </table>
                </div>


                <Modal
                    visible={this.state.editModal}
                    close={() => this.setState({editModal: false, update_fields: {errors: {}}})}>
                    <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                    <form onSubmit={this.editSendRequest}>
                        {this.state.update_fields?.id && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Id</span>
                            <input className="form-control bg-light text-input" name="id" id="id" type="text"
                                   value={this.state.update_fields?.id}
                                   onChange={this.handleChange} style={{width: '400px'}}/>
                        </div>)}


                        {Object.entries(this.state.update_fields)
                            .filter(([k, v], index) => WHITELISTED_MEMBER_COLUMNS.includes(k))
                            .map(([k, v], index) => {
                                return (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">{k}</span>
                                    <input name={k} id={k} type="text"
                                           value={v} onChange={this.handleChange}
                                           style={{width: '400px'}}/>

                                </div>)
                            })}


                        <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                            <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update
                            </button>
                        </div>
                    </form>
                </Modal>

            </div>
        )
    }


    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState, update_fields: {
                ...prevState.update_fields,
                [id]: value
            }
        }))
    }

    editSendRequest = (event) => {
        event.preventDefault();


        let id = this.state.member_account.id;
        let data = {
            action: "MEMBER_ACCOUNT_UPDATE",
            ...Object.fromEntries(Object.entries(this.state.update_fields)
                .filter(([k, v], index) => WHITELISTED_MEMBER_COLUMNS.includes(k))
            )

        };
        if (!window.confirm("Are you sure that you want to update this record?")) return false;


        (this.state.update_fields.id ? (window.app
                .service('member')
                .patch(id, data)) :
            (window.app
                .service('member')
                .create(data)))
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Update',
                    message: "Update Request Successful",
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });

            })
            .catch((err) => {
                console.debug(err)
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}/>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'/>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            });

    };

    componentDidMount() {
        console.debug(this.props.customer);
        this.fetchMemberAccounts()
    }


    fetchMemberAccounts = () => {
        this.setState({table_loading: true});

        window.app
            .service('member')
            .find({
                query: {
                    member_id: this.props.user?.member_ID,

                },
            })
            .then((response) => {

                response.data = response.data.map((d) => {
                    return {
                        id: d.id,
                        member_id: d.member_id,
                        msisdn: d.msisdn,
                        eligible_amount: d.eligible_amount,
                        // eligible_amount: (
                        //     <div>
                        //         <p>{d.eligible_amount}</p>
                        //         <Access permission='MEMBER_UPDATE'>
                        //             <a
                        //                 className='btn btn-sm  btn-outline-dark'
                        //                 href={"#"}
                        //                 onClick={() => {
                        //                     this.setState({
                        //                         editModal: true,
                        //                         member_account: d,
                        //                         update_fields: {
                        //                             errors: {},
                        //                             eligible_amount: d.eligible_amount
                        //                         }
                        //                     });
                        //                 }}
                        //             >
                        //                 Update
                        //             </a>
                        //         </Access>
                        //     </div>
                        //
                        // ),
                        date_created: moment(d.date_created).utc().format('YYYY-MM-DD HH:mm:ss'),

                    };
                });

                this.setState({tableData: response, data: response.data[0], table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default MemberAccount;

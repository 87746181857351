import React, {Component} from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import {CheckCircle, Info, LogOut, RefreshCw, Rewind, Trash, XCircle} from 'react-feather';
import Access from "../../components/accessManager";

class CustomerCreate extends Component {
    state = {inputs: {}};

    render() {
        if (!this.state?.first_name) {
            return (<div>Loading.......</div>);
        } else {
            return (
                <div className=''>
                    <Nav name={"Edit Customer " + this.state.first_name + " " + this.state.other_name}/>


                    <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
                        <code>Current Status:
                            <span
                                className={`badge badge-${
                                    window.global_status[this.state?.statusObj.id].theme
                                }`}>{this.state?.statusObj.status_description}{' '}
                            </span>

                        </code>

                        <div className='d-md-flex flex-row text-center flex-wrap justify-content-end'>

                            <Access permission='ACCOUNT_PIN_RESET'>
                                <button
                                    className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-success btn-round mr-3'
                                    onClick={() => {
                                        if (
                                            !window.confirm(
                                                'Are you sure that you want to reset the PIN for this user?'
                                            )
                                        )
                                            return false;
                                        this.setState({modalVisible: false});
                                        this.updateCustomer({action: 'PIN_RESET'});
                                    }}>
                                    <RefreshCw size={18}/>
                                    <span className='pl-1 font-weight-bold'>Reset Pin</span>
                                </button>
                            </Access>

                            <Access permission='ACCOUNT_SUSPEND'>
                                <button
                                    className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-warning btn-round mr-3'
                                    onClick={() => {
                                        if (
                                            !window.confirm(
                                                'Are you sure that you want to SUSPEND this account?'
                                            )
                                        )
                                            return;
                                        this.setState({modalVisible: false});
                                        this.updateCustomer({action: 'SUSPEND_ACCOUNT'});
                                    }}>
                                    <LogOut size={18}/>
                                    <span className='pl-1 font-weight-bold'>
                      Suspend Account
                    </span>
                                </button>
                            </Access>

                            {this.state.status_id != 6 && <button
                                className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-danger btn-round mr-3'
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            'Are you sure that you want to Delete this account?'
                                        )
                                    )
                                        return;
                                    this.setState({modalVisible: false});
                                    this.updateCustomer({
                                        status_id: 6,
                                        msg: "Customer Delete Request. Status will be changed to Deleted",
                                        action: 'CUSTOMER_UPDATE'
                                    });
                                }}>
                                <Trash size={18}/>
                                <span className='pl-1 font-weight-bold'>Delete Account </span>
                            </button>}
                            {this.state.status_id === 6 && <button
                                className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-outline-danger btn-round mr-3'
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            'Are you sure that you want to restore this deleted Account to Active?'
                                        )
                                    )
                                        return;
                                    this.setState({modalVisible: false});
                                    this.updateCustomer({
                                        status_id: 1,
                                        msg: "Customer Restore Account Request. Status will be changed from Deleted to restored",
                                        action: 'CUSTOMER_UPDATE'
                                    });
                                }}>
                                <Rewind size={18}/>
                                <span className='pl-1 font-weight-bold'>Restore Account </span>
                            </button>}


                        </div>

                        <div className='order-form'>

                            <Form
                                inputs={[
                                    {
                                        label: 'first name',
                                        name: 'first_name',
                                        type: 'text',
                                        value: this.state.first_name,
                                    },
                                    {
                                        label: 'other name',
                                        name: 'other_name',
                                        type: 'text',
                                        value: this.state.other_name,
                                    },
                                    {
                                        label: 'Phone number',
                                        name: 'msisdn',
                                        type: 'text',
                                        value: this.state.msisdn,
                                    },
                                    {
                                        label: 'Enter Member ID',
                                        name: 'member_id',
                                        type: 'number',
                                        value: this.state.member_id,
                                    },
                                    {
                                        label: 'Enter Fosa Account No',
                                        name: 'saving_account_number',
                                        type: 'text',
                                        value: this.state.saving_account_number,
                                    },
                                    {
                                        label: 'Enter Max Send',
                                        name: 'max_send',
                                        type: 'number',
                                        value: this.state.max_send,
                                    },
                                    {
                                        label: 'Enter Max Pesalink',
                                        name: 'max_pesalink',
                                        type: 'number',
                                        value: this.state.max_till,
                                    },
                                    {
                                        label: 'Enter Max Paybill',
                                        name: 'max_paybill',
                                        type: 'number',
                                        value: this.state.max_paybill,
                                    }
                                ]}
                                submit={(data) => {
                                    this.updateCustomer({...data, action: 'CUSTOMER_UPDATE'});

                                }}
                                submitText={"Update Changed Fields"}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount = () => {
        this.fetchCustomer();
    };

    fetchCustomer = () => {
        window.app
            .service('customers')
            .get(this.props.match.params.id)
            .then((response) => {
                this.setState(response);
            });
    };

    updateCustomer = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Updating Customer ...',
            buttons: false,
        });

        window.app
            .service('customers')
            .patch(this.state.id, {...data})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    msisdn: this.state.msisdn,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/customers/' + response.id + '/details');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                console.log(err);
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}/>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            this.props.history.push('/customers/' + this.state.id + '/details');
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'/>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

}

export default CustomerCreate;

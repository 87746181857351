import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import moment from 'moment-timezone';
import {CheckCircle, XCircle} from 'react-feather';

class Withdrawals extends Component {
    state = {
        tableData: {data: []},
        tableData2: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        table_loading2: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Withdrawals'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Transaction Type',
                                    name: 'transaction_type',
                                    options: [
                                        {
                                            label: 'All',
                                            value: null,
                                            default: true,
                                        },
                                        {
                                            label: 'PESALINK',
                                            value: 'PESALINK',
                                        },
                                        {
                                            label: 'MPESA',
                                            value: {
                                                $in:['P2P','BANKTRANSFER','PAYBILL','SO','TILL']
                                            },
                                        }

                                    ],
                                },
                                {
                                    label: 'Reconciliation_status',
                                    name: 'recon',
                                    options: [
                                        {
                                            label: 'All',
                                            value: null,
                                            default: true,
                                        },
                                        {
                                            label: 'RECONCILED',
                                            value: 1,
                                        },
                                        {
                                            label: 'LOCKED/UN-RECONCILED',
                                            value: 0,
                                        }

                                    ],
                                },
                                {
                                    label: 'Amount Range',
                                    name: 'amount',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },
                                        {
                                            label: 'More than 60,000',
                                            value: {
                                                $gte: 60000,
                                            },
                                        },
                                        {
                                            label: 'More than 60,000',
                                            value: {
                                                $gte: 100000,
                                            },
                                        },
                                        {
                                            label: 'More than 140,000',
                                            value: {
                                                $gte: 140000,
                                            },
                                        },
                                        {
                                            label: 'More than 200,000',
                                            value: {
                                                $gte: 200000,
                                            },
                                        },
                                        {
                                            label: 'More than 250,000',
                                            value: {
                                                $gte: 250000,
                                            },
                                        },

                                    ],
                                }
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            dateColumn={'date_processed'}
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchWithdrawals();
                                }, 0);
                            }}
                        />

                        <Table
                            search={['member_id','ref_id', 'transaction_id', 'msisdn','sender_msisdn', 'account_number']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchWithdrawals();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>

                    {/* <div className='p-4'>
                <Table
                  // search={['msisdn', 'debit_account']}
                  // sort="created_at"
                  // sortDirection={-1}
                  data={this.state.tableData2}
                  fetch={(params) => {
                    this.setState({ query2: params });
                    setTimeout(() => {
                      this.fetchDeposits();
                    }, 0);
                  }}
                  loading={this.state.table_loading2}
                  fetchError={this.state.tableError2}
                />
              </div> */}
                </div>
            </div>
        );
    }

    fetchWithdrawals = () => {
        this.setState({table_loading: true});

        let {query, filter} = this.state;
        let q = {...query, ...filter};

        q.member_id = this.props.user;

        window.app
            .service('mpesa-disburse-complete')
            .find({
                query: {
                    ...this.state.query,
                    member_id: this.props.user,
                },
            })
            .then((response) => {
                console.log(response);
                response.data = response.data.map((d) => {

                    return {
                        member:
                            d.memberObj &&
                            d.memberObj.first_name + ' ' + d.memberObj.other_name,
                        memberNo: d.member_id,
                        trxID: d.recon===1? d.resultObj?.transactionId:(
                            <a
                                className='btn btn-sm btn-round btn-danger'
                                href={"#"}
                                onClick={() => {
                                    this.updateWithdrawals(d.id);
                                }}
                            >
                                Clear/Reconcile
                            </a>
                        ),
                        mobileNo: d.msisdn,
                        RecvNo: d.sender_msisdn,
                        RecvBy: d.resultObj?.name,
                        savingAccNo: d.saving_account_no,
                        amount: 'Ksh. ' + d.amount.toLocaleString(),
                        trxType: d.transaction_type,
                        accNo: d.account_number,
                        details: d.payment_details,
                        resp_desc: d.mpesa_resp_desc,
                        transaction_id: d.transaction_id,
                        ref_id: d.ref_id,
                        resultCode: d.resultObj?d.resultObj.resultCode :d.pesalinkObj?.MessageDescription,
                        resultDesc: d.resultObj?d.resultObj.resultDesc :d.pesalinkObj?.DestinationsNarration,
                        trxDate: moment(d.date_processed).utc().format('YYYY-MM-DD HH:mm:ss'),

                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };

    updateWithdrawals = (id) => {
        window.alert2.show({
            loader: true,
            title: 'Reconciling ...',
            buttons: false,
        });

        setTimeout(() => {
            window.app
                .service('recon')
                .create({type: "MPESA_DISBURSE_SINGLE",uniq_id:id})
                .then((response) => {
                    window.alert2.show({
                        loader: false,
                        icon: <CheckCircle size={60}></CheckCircle>,
                        title: 'Reconciled Successfully',
                        buttons: true,
                        onSubmit: () => {
                            this.fetchWithdrawals();
                        },
                    });
                    this.setState(response);
                })
                .catch((err) => {
                    window.alert2.show({
                        loader: false,
                        icon: <XCircle size={60} className='text-danger'></XCircle>,
                        title: 'Error',
                        message: err.message,
                        buttons: true,
                    });
                });
        }, 1000)

    };
}

export default Withdrawals;

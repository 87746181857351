import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import moment from 'moment-timezone';
import {CheckCircle, XCircle} from 'react-feather';

class PesaLink extends Component {
    state = {
        tableData: {data: []},
        tableData2: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        table_loading2: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Pesalink'
                    buttons={[

                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Table
                            search={["DestinationsNarration","DestinationsAccountNumber","DestinationsReferenceNumber","DestinationsTransactionID","MessageReference"]}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetch();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>

                </div>
            </div>
        );
    }

    fetch = () => {
        this.setState({table_loading: true});

        let {query, filter} = this.state;
        let q = {...query, ...filter};

        q.member_id = this.props.user;

        window.app
            .service('pesalink-results')
            .find({
                query: {
                    ...this.state.query,
                    member_id: this.props.user,
                },
            })
            .then((response) => {
                console.log(response);
                response.data = response.data.map((d) => {

                    return {

                          id: d.id,
                          // SourceAmount: d.SourceAmount,
                        // SourceAccountNumber: d.SourceAccountNumber,
                        DestAccNo: d.DestinationsAccountNumber,
                        DestAmount: d.DestinationsAmount.toLocaleString(),
                        DestBankCode: d.DestinationsBankCode,
                        DestinationsNarration: (<code>{d.DestinationsNarration}</code>),
                        MessageCode: d.MessageCode,
                        MessageDateTime: d.MessageDateTime,
                        MessageDescription: d.MessageDescription,
                        MessageReference: d.MessageReference,
                        DestReferenceNumber: d.DestinationsReferenceNumber,
                        DestTransactionID: d.DestinationsTransactionID,
                        processed: d.processed,
                        trxDate: moment(d.datetime).utc().format('YYYY-MM-DD HH:mm:ss'),

                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };

}

export default PesaLink;

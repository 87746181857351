import React, {Component} from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import {CheckCircle, XCircle} from 'react-feather';

class OrderCreate extends Component {
    state = {};

    render() {
        return (
            <div className=''>
                <Nav name='Create Standing Order'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
                    <div className='order-form'>
                        <div className='mx-3 d-inline-block mb-3'>
              <span className='ml-1 mb-2 d-block text-capitalize'>
                Phone Number
              </span>
                            <input
                                type='text'
                                placeholder='...'
                                value={this.props.match.params.msisdn}
                                className='form-control bg-light text-input'
                                disabled={true}
                                readOnly={true}
                            />
                        </div>

                        <div className='mx-3 d-inline-block mb-3'>
                            <span className='ml-1 mb-2 d-block text-capitalize'>Member No.</span>
                            <input
                                type='text'
                                placeholder='...'
                                value={this.props.match.params.member_id}
                                className='form-control bg-light text-input'
                                disabled={true}
                                readOnly={true}
                            />
                        </div>

                        <Form
                            inputs={[
                                {
                                    label: 'next due date',
                                    name: 'next_due_date',
                                    type: 'break',
                                },
                                {
                                    label: 'order type',
                                    name: 'order_type',
                                    type: 'select',
                                    options: window.orderTypes.map((d) => {
                                        return {
                                            name: d.order_type,
                                            value: d.id,
                                        };
                                    }),
                                },
                                {
                                    label: 'next due date',
                                    name: 'next_due_date',
                                    type: 'date',
                                },
                                {
                                    label: 'debit account',
                                    name: 'debit_account',
                                    type: 'number',
                                },
                                {
                                    label: 'Amount',
                                    name: 'amount',
                                    type: 'number',
                                },
                                {
                                    label: 'beneficiary bank',
                                    name: 'beneficiary_bank',
                                    type: 'select',
                                    options: window.banks.map((d) => {
                                        return {
                                            name: d.bank_name,
                                            value: d.id,
                                        };
                                    }),
                                },
                                {
                                    label: 'beneficiary account',
                                    name: 'beneficiary_account',
                                    type: 'number',
                                },

                                {
                                    label: 'description',
                                    name: 'description',
                                    type: 'textarea',
                                },

                                //

                                // {
                                //   label: 'run date',
                                //   name: 'run_date',
                                //   type: 'number',
                                // },
                            ]}
                            submit={(data) => {
                                this.createOrder({
                                    ...data,
                                    debit_description: 'STAFF ACCOUNT',
                                    run_count: 0,
                                    frequency: 1,
                                    run_date: new Date(data.next_due_date).getDate(),
                                });
                            }}></Form>
                    </div>
                </div>
            </div>
        );
    }

    createOrder = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Creating Order ...',
            buttons: false,
        });

        window.app
            .service('standing-order')
            .create({
                ...data,
                member_id: this.props.match.params.member_id,
                msisdn: this.props.match.params.msisdn,
            })
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Standing Order created Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push(
                            `/customers/${this.props.match.params.id}/standing-orders`
                        );
                    },
                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });
    };
}

export default OrderCreate;

import React, {Component} from 'react';
import Table from '../../components/Table';
import {Link} from 'react-router-dom';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';
import Access from '../../components/accessManager';

class MakerChecker extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Maker Checker'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                // {
                                //   label: 'Status',
                                //   name: 'service_response_status',
                                //   options: [
                                //     {
                                //       label: 'All',
                                //       default: true,
                                //     },
                                //     {
                                //       label: 'Pending',
                                //       value: null,
                                //       default: true,
                                //     },
                                //     {
                                //       label: 'Approved',
                                //       value: 200,
                                //     },
                                //     {
                                //       label: 'Rejected',
                                //       value: 400,
                                //     },
                                //   ],
                                // },
                            ]}
                            // dateRange={['startdate', 'enddate']}
                            // dateRangeLabel='Date Range'
                            onChange={(filter) => {
                                let {query} = this.state;

                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchMakerChecker();
                                }, 0);
                            }}
                        />

                        <Table
                            //   search={[ 'first_name', 'other_name']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchMakerChecker();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                        />
                    </div>
                </div>

                {this.state.json && (
                    <Modal
                        visible={true}
                        close={() => {
                            this.setState({json: null});
                        }}
                    >
                        <h5 className='font-weight-bold'>Data Preview</h5>
                        <hr/>
                        <ReactJson
                            displayDataTypes={false}
                            displayObjectSize={false}
                            src={this.state.json}
                        />
                    </Modal>
                )}
            </div>
        );
    }

    getAffectedCustomer = (row) => {
        console.log(row)
        let service_payload = JSON.parse(row.service_payload);

        // Get the action for each makerchecker to help decide what function to call

        let _type = service_payload?.data?.action
        console.log(_type)
        let type_functions = {
            "DEFAULT": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `ID:${row.uniq_key}`
                let query = `by_id/${row.uniq_key}`

                return [query, txt]
            },
            "PIN_RESET": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `ID:${row.uniq_key}`
                let query = `by_id/${row.uniq_key}`

                return [query, txt]
            },
            "SUSPEND_ACCOUNT": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `ID:${row.uniq_key}`
                let query = `by_id/${row.uniq_key}`

                return [query, txt]
            },
            "REGISTER": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `MSISDN:${data.msisdn}`
                let query = `by_member_id/${data.member_id}`

                return [query, txt]
            },
            "CUSTOMER_UPDATE": (payload) => {
                let data = payload.data;
                // let data = payload.data;

                let txt = `MSISDN:${data.msisdn}`
                let query = `by_member_id/${data.member_id}`

                return [query, txt]
            },
        }

        let results=[{}, "Default"]
        if(_type in type_functions){
            results = type_functions[_type](service_payload)
        }
        return results
    }


    fetchMakerChecker = () => {
        let {query} = this.state;

        this.setState({table_loading: true});

        window.app
            .service('service-transactions')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    let affected_customer = this.getAffectedCustomer(d)
                    return {
                        Initiator: (
                            <Access permission='all'>
                                <Link
                                    className=''
                                    to={`/admins/${d.adminObj.id}`}>
                                    {d.adminObj.name}
                                </Link>
                            </Access>
                        ),
                        "Action": (<>
                            <code>{JSON.parse(d.service_payload)?.data?.action}</code>
                        </>),
                        customer: affected_customer[1] !=="Default" ? (
                            <Access permission='all'>
                                <Link
                                    className=''
                                    to={`/customers/${affected_customer[0]}/details`}>
                                    {affected_customer[1]}
                                </Link>
                            </Access>
                        ):(<p>{affected_customer[1]}</p>),
                        Approval_level: d.level,
                        method: `${d.service_method}/${d.service_name}`,
                        status: ({
                            null: (<span className={"badge badge-info"}>PENDING</span>),
                            200: (<span className={"badge badge-success"}>SUCCESS</span>),
                            500: (<span className={"badge badge-danger"}>REJECTED</span>)
                        })[d.service_response_status],
                        payload: d.service_payload && (
                            <button
                                className='btn btn-sm btn-outline-success font-weight-bold'
                                onClick={() =>
                                    this.setState({json: JSON.parse(d.service_payload)?.data})
                                }
                            >
                                View
                            </button>
                        ),
                        response: d.service_response_txt && (
                            <button
                                className='btn btn-sm btn-outline-success font-weight-bold'
                                onClick={() =>
                                    this.setState({json: JSON.parse(d.service_response_txt)})
                                }
                            >
                                View
                            </button>
                        ),
                        //transaction_id: d.transaction_id,
                        //transaction_type: (
                        //<span className={`badge badge`}> {d.transaction_type} </span>
                        //),
                        //tries: d.tries,

                        action: !d.service_response_status ? (
                            <div className='d-flex flex-row'>
                                <Link
                                    to={`/maker-checker/${d.id}/${d.service_response_status}`}
                                    className='btn btn-sm btn-round btn-outline-success px-3 mr-3'
                                >
                                    Approve
                                </Link>

                                <Link
                                    to={`/maker-checker/${d.id}/${d.service_response_status}`}
                                    className='btn btn-sm btn-round btn-outline-danger px-3'
                                >
                                    Reject
                                </Link>
                            </div>
                        ) : (
                            <Link
                                to={`/maker-checker/${d.id}/${d.service_response_status}`}
                                className='btn btn-sm btn-round btn-outline-primary px-3'
                            >
                                view
                            </Link>
                        ),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default MakerChecker;

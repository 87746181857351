import React, {Component} from 'react';
import Table from '../components/Table';
import {Link} from 'react-router-dom';
import Nav from '../components/Nav';
import Filter from '../components/filter';
import moment from 'moment-timezone';
import {XCircle} from 'react-feather';

class Orders extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Standing Orders'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Status',
                                    name: 'status_id',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },
                                        ...window.global_status
                                            .filter((d) => d.type.includes('so'))
                                            .map((d) => {
                                                return {
                                                    label: d.status_description,
                                                    value: d.id,
                                                };
                                            }),
                                    ],
                                },
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            onChange={(filter) => {
                                let {query} = this.state;

                                this.setState({filter});
                                setTimeout(() => {
                                    this.fetchOrders();
                                }, 0);
                            }}
                        />
                        <Table
                            search={['msisdn', 'debit_account']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchOrders();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchOrders = () => {
        this.setState({table_loading: true});

        let {query, filter} = this.state;
        let q = {...query, ...filter};

        if (q.startdate) {
            q.next_due_date = {
                $gte: q.startdate,
                $lte: q.enddate,
            };
            delete q.startdate;
            delete q.enddate;
        }

        q.member_id = this.props.user;

        window.app
            .service('standing-order')
            .find({
                query: q,
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        // id: 512,
                        member:
                            d.memberObj &&
                            d.memberObj.first_name + ' ' + d.memberObj.other_name,
                        'Phone Number': d.msisdn,
                        // member_id:d.member_id,
                        order_type: d.typeObj.order_type,
                        frequency: d.frequency,
                        run_date: d.run_date,
                        amount: (
                            <span className='no-wrap'>KES {d.amount.toLocaleString()}</span>
                        ),
                        debit_account: d.debit_account,
                        debit_description: d.debit_description,
                        beneficiary_account: d.beneficiary_account,
                        beneficiary_bank: d.beneficiary_bank,
                        description: d.description,
                        status: d.statusObj && (
                            <span
                                className={`badge badge-${
                                    window.global_status[d.statusObj.id].theme
                                }`}>
                {d.statusObj.status_description}{' '}
              </span>
                        ),
                        date_created:
                            d.date_created &&
                            moment(d.date_created).utc().format('YYYY-MM-DD HH:mm:ss'),
                        next_due_date:
                            d.next_due_date &&
                            moment(d.next_due_date).utc().format('YYYY-MM-DD HH:mm:ss'),
                        last_processed_date:
                            d.last_processed_date &&
                            moment(d.last_processed_date).utc().format('YYYY-MM-DD HH:mm:ss'),
                        run_count: d.run_count,
                        last_notification_date:
                            d.last_notification_date &&
                            moment(d.last_notification_date).utc().format('YYYY-MM-DD HH:mm:ss'),
                        actions: (
                            <div className='d-flex flex-row'>
                                {d.status_id != 6 && (
                                    <button
                                        className='btn btn-sm btn-round btn-outline-danger px-3 mr-3'
                                        onClick={() => {
                                            if (
                                                !window.confirm(
                                                    'Are you sure that you want to delete this item?'
                                                )
                                            )
                                                return false;
                                            this.modifyItem(d.id, 6);
                                        }}>
                                        Deactivate
                                    </button>)}
                                {d.status_id == 6 && (
                                    <button
                                        className='btn btn-sm btn-round btn-outline-success px-3 mr-3'
                                        onClick={() => {
                                            if (
                                                !window.confirm(
                                                    'Are you sure that you want to delete this item?'
                                                )
                                            )
                                                return false;
                                            this.modifyItem(d.id, 1);
                                        }}>
                                        Activate
                                    </button>
                                )}
                                {d.memberObj && (
                                    <Link
                                        className='btn btn-sm btn-round btn-outline-primary px-3 mr-3'
                                        to={`/customers/${d.memberObj.id}/standing-orders`}>
                                        View
                                    </Link>
                                )}
                            </div>
                        ),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
    modifyItem = (id, status_id) => {
        window.alert2.show({
            loader: true,
            title: 'Removing Item ...',
            buttons: false,
        });

        window.app
            .service('standing-order')
            .patch(id, {status_id: status_id})
            .then((response) => {
                window.alert2.hide();
                window.alert2.notify({
                    title: 'Removed Successfully',
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };
}

export default Orders;

import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from "../../components/filter";

class Dividends extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Dividends'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[]}
                            dateRange={['startdate', 'enddate']}
                            dateColumn={'date_created'}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetch();
                                }, 0);
                            }}
                        />
                        <Table
                            search={['member_id', 'msisdn','account_no']}
                            sort="id"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetch();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }


    fetch = () => {
        this.setState({table_loading: true});

        window.app
            .service('dividends')
            .find({
                query: {
                    ...this.state.query,
                    member_id: this.props.user,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        ...d,
                        date_created: moment(d.date_created).utc().format('YYYY-MM-DD HH:mm:ss'),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Dividends;

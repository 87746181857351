import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import moment from 'moment-timezone';
import {CheckCircle, XCircle} from 'react-feather';

class Results extends Component {
    state = {
        tableData: {data: []},
        tableData2: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        table_loading2: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Results'
                    buttons={[

                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        {/*<Filter*/}
                        {/*    filter={[*/}
                        {/*        {*/}
                        {/*            label: 'Amount Range',*/}
                        {/*            name: 'amount',*/}
                        {/*            options: [*/}
                        {/*                {*/}
                        {/*                    label: 'All',*/}
                        {/*                    value: 0,*/}
                        {/*                    default: true,*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    label: 'More than 60,000',*/}
                        {/*                    value: {*/}
                        {/*                        $gte: 60000,*/}
                        {/*                    },*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    label: 'More than 60,000',*/}
                        {/*                    value: {*/}
                        {/*                        $gte: 100000,*/}
                        {/*                    },*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    label: 'More than 140,000',*/}
                        {/*                    value: {*/}
                        {/*                        $gte: 140000,*/}
                        {/*                    },*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    label: 'More than 200,000',*/}
                        {/*                    value: {*/}
                        {/*                        $gte: 200000,*/}
                        {/*                    },*/}
                        {/*                },*/}
                        {/*                {*/}
                        {/*                    label: 'More than 250,000',*/}
                        {/*                    value: {*/}
                        {/*                        $gte: 250000,*/}
                        {/*                    },*/}
                        {/*                },*/}

                        {/*            ],*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*    dateRange={['startdate', 'enddate']}*/}
                        {/*    dateRangeLabel='Date Range'*/}
                        {/*    dateColumn={'date_processed'}*/}
                        {/*    onChange={(filter) => {*/}
                        {/*        let {query} = this.state;*/}
                        {/*        this.setState({query: {...query, ...filter}});*/}
                        {/*        setTimeout(() => {*/}
                        {/*            this.fetchWithdrawals();*/}
                        {/*        }, 0);*/}
                        {/*    }}*/}
                        {/*/>*/}

                        <Table
                            search={['resultCode', 'transactionId', 'msisdn', 'conversationId','originalConversationId']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetch();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>


                </div>
            </div>
        );
    }

    fetch = () => {
        this.setState({table_loading: true});

        let {query, filter} = this.state;
        let q = {...query, ...filter};

        q.member_id = this.props.user;

        window.app
            .service('results')
            .find({
                query: {
                    ...this.state.query,
                    // member_id: this.props.user,
                },
            })
            .then((response) => {
                console.log(response);
                response.data = response.data.map((d) => {

                    return {
                          id: d.id,
                          originalConversationId: d.originalConversationId,
                          resultType: d.resultType,
                          resultCode: d.resultCode,
                          resultDesc: d.resultDesc,
                          conversationId: d.conversationId,
                          amount: 'Ksh. ' + d.amount.toLocaleString(),
                          transactionId: d.transactionId,
                          msisdn: d.msisdn,
                          name: d.name,
                          availableFunds: d.availableFunds,
                          processed: d.processed,
                          process_desc: d.process_desc,
                          // datetime: d.datetime,

                        transactionDate: moment(d.transactionDate).utc().format('YYYY-MM-DD HH:mm:ss'),

                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };

}

export default Results;

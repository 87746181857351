import React, {Component} from 'react';
import moment from 'moment-timezone';
import Registration from "./registration2";
import MemberAccount from "./member_account";

class UserSummarry extends Component {
    state = {data: {}};

    render() {
        return (
            <div>
                <div className='row p-3'>
                    <div className='col-lg-6 col-md-6 col-sm-6'>
                        <div className='card'>
                            <table className='table table-striped table-hover'>
                                <thead>
                                <th>Customer Account</th>
                                <th></th>
                                </thead>

                                {Object.keys(this.state.data).slice(0, Math.ceil(Object.keys(this.state.data).length / 1)).map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className='text-capitalize'>
                                                {d.replace(/\_/g, ' ')}
                                            </td>
                                            <td>{this.state.data[d] ? this.state.data[d] : '-'}</td>
                                        </tr>
                                    );
                                })}

                            </table>
                        </div>
                    </div>

                    {this.state.data.member_ID && <MemberAccount user={this.state.data}/>}


                </div>
                <div className='row p-3'>

                    <div className='col-md-12'>
                        {/*<div className='col-lg-6 col-md-6 col-sm-12'>*/}
                        <div className='card'>
                            <div className='card-header'>
                                <h4>Registration Requests</h4>
                            </div>

                            {this.state.data.phone_number && <Registration user={this.state.data}/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        console.log(this.props.data);
        let d = this.props.data;

        let data = {
            member_ID: d.member_id,
            first_name: d.first_name,
            other_name: d.other_name,
            phone_number: d.msisdn,
            device_id: d.device_id,
            device_name: d.device_name,
            device_os: d.os_ver,
            max_paybill: d.max_paybill,
            max_send: d.max_send,
            // loanable: d.loanable,
            score: d.score,
            max_till: d.max_till,
            saving_account_number: d.saving_account_number,
            date_created: moment(d.date_created).utc().format('Do MMMM YYYY hh:mm'),
            last_activity_date: d.last_activity_date,
            last_reset_date: d.last_reset_date,
            status_id: d.status_id,
            status: (
                <span
                    className={`badge badge-${
                        window.global_status[d.statusObj.id].theme
                    }`}>
                {d.statusObj.status_description}{' '}
              </span>
            ),

            tries: d.tries + '',
        };

        this.setState({data});
    }
}

export default UserSummarry;

import React, {Component} from 'react';
import Table from '../../components/Table';
import {Link} from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from '../../components/filter';
import Access from '../../components/accessManager';

class Customers extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Customers'
                    buttons={[
                        {
                            text: 'Add a Customer',
                            link: '/customer/create',
                            permission: 'CUSTOMER_CREATE',
                        },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shadow mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Status',
                                    name: 'status_id',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },

                                        ...window.global_status
                                            .filter((d) => d.type.includes('cust'))
                                            .map((d) => {
                                                return {
                                                    label: d.status_description,
                                                    value: d.id,
                                                };
                                            }),
                                    ],
                                }
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchCustomers();
                                }, 0);
                            }}
                        />

                        <Table
                            search={['member_id', 'msisdn', 'first_name', 'other_name', 'saving_account_number']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchCustomers();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            csv={() => ({
                                name: 'customers',
                                query: {...this.state.query, ...this.state.filter},
                            })}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchCustomers = () => {
        let {query} = this.state;

        this.setState({table_loading: true});


        window.app
            .service('customers')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        // id: d.id,
                        member_id: d.member_id,
                        // saving_account_number: d.saving_account_number,
                        name: d.first_name + ' ' + d.other_name,
                        phone: d.msisdn,
                        fosa_account_number: d.saving_account_number,
                        // last_activity_date:
                        //   d.last_activity_date &&
                        //   moment(d.last_activity_date).utc().format('Do MMM YYYY'),
                        // last_reset_date: d.last_reset_date,
                        date_created: moment(d.date_created).utc().format('YYYY-MM-DD HH:mm:ss'),
                        tries: d.tries,
                        device_name:d.device_name,
                        device_version:d.os_ver,
                        status: (
                            <span
                                className={`badge badge-${
                                    window.global_status[d.statusObj.id].theme
                                }`}>
                {d.statusObj.status_description}{' '}
              </span>
                        ),
                        // password: d.password,
                        action: (
                            <Access permission='CUSTOMER_DETAILS_READ'>
                                <Link
                                    className='btn btn-sm btn-round btn-outline-primary px-3'
                                    to={`/customers/by_id/${d.id}/details`}>
                                    View
                                </Link>
                            </Access>
                        ),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Customers;

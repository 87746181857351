import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from "../../components/filter";

class Loans extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Loans'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[]}
                            dateRange={['startdate', 'enddate']}
                            dateColumn={'date_created'}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchLoans();
                                }, 0);
                            }}
                        />
                        <Table
                            // search={['firstname', 'lastname']}
                            sort="id"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchLoans();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }


    fetchLoans = () => {
        this.setState({table_loading: true});

        window.app
            .service('loans')
            .find({
                query: {
                    ...this.state.query,

                    member_no: this.props.user,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        customer: d.memberObj.first_name + ' ' + d.memberObj.other_name,
                        'Phone Number': d.memberObj.msisdn,
                        amount: 'KES ' + d.amount.toLocaleString(),
                        disbursement_date: moment(d.disbursement_date).utc().format(
                            'YYYY-MM-DD HH:mm:ss'
                        ),
                        description: d.description,
                        product_id: d.product_id,
                        created_at: moment(d.created_at).utc().format('YYYY-MM-DD HH:mm:ss'),
                        // updated_at: d.updated_at
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Loans;

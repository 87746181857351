import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import Filter from '../../components/filter';
import Modal from '../../components/modal';
import ReactJson from 'react-json-view';

class MerchantTransactions extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                {!this.props.user && <Nav name='Merchant Transactions '></Nav>}
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Amount',
                                    name: 'amount',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },
                                        {
                                            label: 'More that 10000',
                                            value: 10000,
                                        },
                                        {
                                            label: 'more than 30000',
                                            value: 10000,
                                        },
                                    ],
                                },
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                this.setState({filter});
                                setTimeout(() => {
                                    this.fetchMerchantTransactions();
                                }, 0);
                            }}
                        />
                        <Table
                            // search={['firstname', 'lastname']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(query) => {
                                this.setState({query});
                                setTimeout(() => {
                                    this.fetchMerchantTransactions();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>

                    {this.state.json && (
                        <Modal
                            visible={true}
                            close={() => {
                                this.setState({json: null});
                            }}>
                            <ReactJson
                                displayDataTypes={false}
                                displayObjectSize={false}
                                src={this.state.json}
                            />
                        </Modal>
                    )}
                </div>
            </div>
        );
    }

    fetchMerchantTransactions = () => {
        this.setState({table_loading: true});

        let {query, filter} = this.state;
        let q = {...query, ...filter};

        if (q.startdate) {
            q.date_created = {
                $gte: q.startdate,
                $lte: q.enddate,
            };
            delete q.startdate;
            delete q.enddate;
        }

        q.member_id = this.props.user;

        window.app
            .service('merchant-transaction')
            .find({
                query: {
                    ...q,
                    member_id: this.props.user,
                },
            })
            .then((response) => {
                console.log(response);

                // response.data = response.data.map((d) => {
                //   return {
                //     // id: d.id,
                //     // ref_id: d.ref_id,
                //     member:
                //       d.memberObj &&
                //       d.memberObj.first_name + ' ' + d.memberObj.other_name,
                //     'Phone Number': d.msisdn,
                //     rcpt_msisdn: d.rcpt_msisdn,
                //     amount: (
                //       <span className='no-wrap'>KES {d.amount.toLocaleString()}</span>
                //     ),
                //     from_account: d.from_account,
                //     to_account: d.to_account,
                //     message: d.message,
                //     request: d.request && (
                //       <button
                //         className='btn btn-sm btn-outline-primary font-weight-bold'
                //         onClick={() => this.setState({ json: JSON.parse(d.request) })}>
                //         view
                //       </button>
                //     ),
                //     response: d.response && (
                //       <button
                //         className='btn btn-sm btn-outline- font-weight-bold'
                //         onClick={() => this.setState({ json: JSON.parse(d.response) })}>
                //         view
                //       </button>
                //     ),
                //     status: d.statusObj && d.statusObj.status_description,
                //     transaction_type: d.typeObj.transaction_type_desc,
                //     date_created: moment(d.date_created).utc().format('Do MMMM YYYY hh:mm a'),
                //     date_modified: moment(d.date_modified).utc().format(
                //       'Do MMMM YYYY hh:mm a'
                //     ),
                //   };
                // });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default MerchantTransactions;

import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';

import './globals';
// pages
import Login from './pages/login';
import Home from './pages/home';
import Menu from './components/Menu';
import Alert from './components/alert';
import Customers from './pages/users/customers';
import Registration from './pages/users/registration';
import customerDetails from './pages/users/customerDetails';
import Admins from './pages/users/admins';
import MerchantTransactions from './pages/finance/merchantTransactions';
import MerchantTransactionsProcessed from './pages/finance/merchantTransactionProcessed';
import MerchantTransactionsSuccess from './pages/finance/merchantTransactionsSuccess';
import Transactions from './pages/finance/transactions';
import Loans from './pages/loans/loans';
import CustomerCreate from './pages/users/customerCreate';
import CustomerEdit from './pages/users/customerUpdate';
import Orders from './pages/orders';
import StandingOrders from './pages/standing_orders';
import Withrawals from './pages/finance/withdrawals';
import Withdrawals from './pages/finance/withdrawals';
import AuditTrail from './pages/audit/audit';
import AuditData from './pages/audit/auditData';
import AuditEntry from './pages/audit/auditEntry';
import AuditError from './pages/audit/auditError';
import AuditJavascript from './pages/audit/auditJavascript';
import AuditMail from './pages/audit/auditMail';
import SMS from './pages/sms/sms';
import SMSList from './pages/sms/smsList';
import SMSCreate from './pages/sms/smsCreate';
import Roles from './pages/admin/roles';
import IPAdress from './pages/ip_address';
import OrderCreate from './pages/orders/orderCreate';
import AdminCreate from './pages/admin/adminCreate';
import MakerChecker from './pages/admin/makerChecker';
import MakerCheckerLogs from './pages/admin/makerCheckerLogs';
import Dashboards from './pages/dashboards';
import Deposits from './pages/finance/deposits';
import Access from './components/accessManager';
import AdminEdit from './pages/admin/adminEdit';
import PesaLink from "./pages/finance/pesalink";
import Results from "./pages/finance/results";
import Card from "./pages/card";
import Dividends from "./pages/dividends/dividends";

class App extends Component {
    state = {blur: false, authenticated: false};

    render() {
        return (
            <>
                <Alert
                    onRef={(ref) => {
                        window.alert2 = ref;
                    }}
                    toggle={(blur) => {
                        this.setState({blur});
                    }}></Alert>
                <div
                    className={`page-blur pa
        ge ${this.state.blur && 'blur-alert'}`}>
                    <Access permission='all'>
                        <Route path='/login' exact component={Login}></Route>
                    </Access>

                    {this.state.authenticated && (
                        <Portal hist={this.props.history}></Portal>
                    )}
                </div>
                <div
                    className={`preloader-cover ${
                        this.state.loaded && 'hide-preloader'
                    }`}>
                    <div className='alert-loader px-3 my-3 d-flex flex-row justify-content-center'>
                        <div className='nest-parent'>
                            <div id='nest1'></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        window.app
            .reAuthenticate()
            .then((response) => {
                // console.log(response);
                this.fetchDefaults();

                let path = window.location.pathname;

                console.log('path before ', path);

                if (path.includes('login') || path.includes('get-started'))
                    return false;
                console.log('path after ', path);
            })
            .catch((error) => {
                console.log(error);
                this.setState({loaded: true, authenticated: false});
                let p = window.location.pathname;
                if (!['/password-change', '/password-forgot'].includes(p))
                    this.props.history.push('/login');
            });
    }

    get = (model, callback, filter) => {
        window.app
            .service(model)
            .find({
                query: {
                    $limit: 250,
                    $skip: 0,
                    ...(filter ? filter : {}),
                },
            })
            .then((response) => {
                let data = [];
                response.data.map((d) => {
                    data[d.id] = d;
                });
                callback(data);
                this.verifyFetch();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    fetchDefaults = () => {
        this.get(
            `admin-group-roles`,
            (response) => {
                let roles = {};
                response.map((d) => {
                    if (!roles[d.admin_role.name]) roles[d.admin_role.name] = [];
                    roles[d.admin_role.name].push(d.admin_group.id);
                });

                window.roles = roles;
            },
            {
                group_id: window.user && window.user.admin.group_id,
            }
        );

        this.get(`admin-groups`, (response) => {
            console.log(response);
            window.groups = response;
        });

        this.get(`transaction-type`, (response) => {
            window.transactionType = response;
        });

        this.get(`bank`, (response) => {
            window.banks = response;
        });

        this.get(`status`, (response) => {
            window.global_status = response;

            window.global_status[1].theme = 'success';
            window.global_status[2].theme = 'primary';
            window.global_status[3].theme = 'danger';
            window.global_status[4].theme = 'warning';
            window.global_status[5].theme = 'warning';
            window.global_status[6].theme = 'danger';
            window.global_status[7].theme = 'success';
            window.global_status[8].theme = 'danger';
        });

        this.get(`order-type`, (response) => {
            window.orderTypes = response;
        });

        // let roles = {};
        // roles = { CUSTOMER_READ: [1], CUSTOMER_CREATE: [1] };
        // window.roles = roles;
        this.verifyFetch();
    };

    verifyFetch = () => {
        if (
            window.roles &&
            window.global_status &&
            window.orderTypes &&
            window.groups &&
            window.banks
        ) {
            let loaded = false;
            if (localStorage.user) loaded = true;
            this.setState({authenticated: true, loaded});
        }
    };
}

class Portal extends Component {
    state = {};
    history = this.props.hist;

    render() {
        return (
            <>
                <div id='wrapper' className='pt-5 mt-md-0 pt-md-0 mt-2'>
                    <Menu></Menu>
                    <div id='content-wrapper' className='d-flex flex-column bg-light'>
                        <div className='d-flex flex-fill flex-column'>
                            <Access permission='all'>
                                <Route path='/' exact component={Home}/>
                            </Access>

                            <Access permission='all'>
                                <Route path='/home' exact component={Home}/>
                            </Access>

                            <Access permission='CUSTOMER_READ'>
                                <Route path='/customers' exact component={Customers}/>
                            </Access>
                            <Access permission='CUSTOMER_READ'>
                                <Route path='/registration-requests/all' exact component={Registration}/>
                            </Access>

                            <Access permission='CUSTOMER_READ'>
                                <Route path='/registration-requests/pending_kyc' exact component={Registration}/>
                            </Access>

                            <Access permission='CUSTOMER_READ'>
                                <Route path='/registration-requests/pending_payment' exact component={Registration}/>
                            </Access>

                            <Access permission='CUSTOMER_READ'>
                                <Route path='/registration-requests/pending_approval' exact component={Registration}/>
                            </Access>
                            <Access permission='CUSTOMER_READ'>
                                <Route path='/registration-requests/success' exact component={Registration}/>
                            </Access>

                            <Access permission='CUSTOMER_READ'>
                                <Route path='/registration-requests/failures' exact component={Registration}/>
                            </Access>


                            <Access permission='ADMIN_READ'>
                                <Route path='/admins' exact component={Admins}/>
                            </Access>

                            <Access permission='ADMIN_CREATE'>
                                <Route path='/admins/edit/:id' exact component={AdminEdit}/>
                            </Access>

                            <Access permission='ROLES_READ'>
                                <Route path='/roles' exact component={Roles}/>
                            </Access>

                            <Access permission='CUSTOMER_DETAILS_READ'>
                                <Route path='/customers/by_id/:id' component={customerDetails}/>
                            </Access>

                            <Access permission='CUSTOMER_DETAILS_READ'>
                                <Route path='/customers/by_member_id/:member_id' component={customerDetails}/>
                            </Access>

                            <Access permission='CUSTOMER_CREATE'>
                                <Route path='/customer/create' component={CustomerCreate}/>
                            </Access>

                            <Access permission='CUSTOMER_PATCH'>
                                <Route path='/customer/edit/:id' component={CustomerEdit}/>
                            </Access>

                            <Access permission='TRANSACTIONS_READ'>
                                <Route path='/transactions' exact component={Transactions}/>
                            </Access>

                            <Access permission='MERCHANT_TRANSACTIONS_READ'>
                                <Route
                                    path='/merchant-transactions'
                                    exact
                                    component={MerchantTransactions}
                                />
                            </Access>

                            <Access permission='all'>
                                <Route
                                    path='/merchant-transactions-processed'
                                    exact
                                    component={MerchantTransactionsProcessed}
                                />
                            </Access>

                            <Access permission='all'>
                                <Route
                                    path='/merchant-transactions-success'
                                    exact
                                    component={MerchantTransactionsSuccess}
                                />
                            </Access>

                            <Access permission='LOANS_READ'>
                                <Route path='/loans' exact component={Loans}/>
                            </Access>

                            <Access permission='DIVIDENDS_READ'>
                                <Route path='/dividends' exact component={Dividends}/>
                            </Access>

                            <Access permission='SMS_READ'>
                                <Route path='/sms' exact component={SMS}/>
                            </Access>
                            <Access permission='CARD_READ'>
                                <Route path='/card_records' exact component={Card}/>
                            </Access>

                            <Access permission='SMS_READ'>
                                <Route path='/sms-list' exact component={SMSList}/>
                            </Access>

                            <Access permission='all'>
                                <Route path='/sms-create' exact component={SMSCreate}/>
                            </Access>

                            <Access permission='STANDING_ORDERS_READ'>
                                <Route path='/standing_orders' exact component={StandingOrders}/>
                            </Access>

                            <Access permission='STANDING_ORDERS_READ'>
                                <Route path='/orders' exact component={Orders}/>
                            </Access>

                            <Access permission='WITHDRAWALS_READ'>
                                <Route path='/payments/withdraws' exact component={Withrawals}/>
                            </Access>

                            <Access permission='WITHDRAWALS_READ'>
                                <Route path='/payments/deposits' exact component={Deposits}/>
                            </Access>

                            <Access permission='WITHDRAWALS_READ'>
                                <Route path='/payments/mpesa-results' exact component={Results}/>
                            </Access>

                            <Access permission='WITHDRAWALS_READ'>
                                <Route path='/payments/pesalink' exact component={PesaLink}/>
                            </Access>

                            <Access permission='AUDIT_READ'>
                                <Route path='/audit' exact component={AuditTrail}/>
                            </Access>

                            <Access permission='AUDIT_READ'>
                                <Route path='/audit-data' exact component={AuditData}/>
                            </Access>

                            <Access permission='AUDIT_READ'>
                                <Route path='/audit-entry' exact component={AuditEntry}/>
                            </Access>

                            <Access permission='AUDIT_READ'>
                                <Route path='/audit-error' exact component={AuditError}/>
                            </Access>
                            <Access permission='AUDIT_READ'>
                                <Route
                                    path='/audit-javascript'
                                    exact
                                    component={AuditJavascript}
                                />
                            </Access>

                            <Access permission='AUDIT_READ'>
                                <Route path='/audit-mail' exact component={AuditMail}/>
                            </Access>

                            <Access permission='IP_ADDRESS_READ'>
                                <Route path='/ip' exact component={IPAdress}/>
                            </Access>

                            <Access permission='WITHDRAWALS_READ'>
                                <Route path='/withdrawals' exact component={Withdrawals}/>
                            </Access>

                            <Access permission='DEPOSITS_READ'>
                                <Route path='/deposits' exact component={Deposits}/>
                            </Access>
                            <Access permission='STANDING_ORDERS_CREATE'>
                                <Route
                                    path='/order-create/:id/:member_id/:msisdn'
                                    exact
                                    component={OrderCreate}
                                />
                            </Access>

                            <Access permission='ADMIN_CREATE'>
                                <Route path='/admin-create' exact component={AdminCreate}/>
                            </Access>

                            <Access permission='MAKER_CHECKER_READ'>
                                <Route path='/maker-checker' exact component={MakerChecker}/>
                            </Access>

                            <Access permission='all'>
                                <Route path='/dashboards/:type' exact component={Dashboards}/>
                            </Access>
                            <Access permission='MAKER_CHECKER_CONFIRM'>
                                <Route
                                    path='/maker-checker/:id/:status'
                                    exact
                                    component={MakerCheckerLogs}
                                />
                            </Access>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount() {
        if (!localStorage.user) this.props.history.push('/login');
    }
}

export default withRouter(App);

import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from '../../components/filter';
import {CheckCircle, Info, UserCheck} from "react-feather";
import Modal from "../../components/modal";
import ReactJson from "react-json-view";
import Tabs from "../../components/tabs";
import {Route} from "react-router-dom";
import UserSummarry from "./summarry";
import Transactions from "../finance/transactions";
import Withdrawals from "../finance/withdrawals";
import PesaLink from "../finance/pesalink";
import Deposits from "../finance/deposits";
import Loans from "../loans/loans";
import Orders from "../orders";
import Dbqueue from "../sms/dbqueue";
import AuditTrail from "../audit/audit";

class Registration extends Component {
    state = {
        moreDetailsModal: false,
        moreDetailsData: {},
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Registration Requests'
                    buttons={[]}></Nav>
                <div className='mt-3 table-card border-0 card shadow mx-3 shadow'>
                            <Tabs>
                                <Route
                                    permission='all'
                                    path={`/registration-requests/all`}
                                    title='ALL RECORDS'>
                                    <div className='p-4'>

                                        <Table
                                            search={['msisdn', 'id_number']}
                                            // sort="created_at"
                                            // sortDirection={-1}
                                            data={this.state.tableData}
                                            fetch={(params) => {
                                                this.setState({query: {...this.state.query, ...params}});
                                                setTimeout(() => {
                                                    this.fetchRegistration("all");
                                                }, 0);
                                            }}
                                            loading={this.state.table_loading}
                                            fetchError={this.state.tableError}
                                            dateRange={['startdate', 'enddate']}
                                            dateRangeLabel='Date Range'
                                            csv={() => ({
                                                name: 'registrations',
                                                query: {...this.state.query, ...this.state.filter},
                                            })}
                                        />
                                    </div>
                                </Route>

                                <Route
                                    permission='all'
                                    path={`/registration-requests/pending_payment`}
                                    title='1.PendingPayment'>
                                    <div className='p-4'>

                                        <Table
                                            search={['msisdn', 'id_number']}
                                            // sort="created_at"
                                            // sortDirection={-1}
                                            data={this.state.tableData}
                                            fetch={(params) => {
                                                this.setState({query: {...this.state.query, ...params}});
                                                setTimeout(() => {
                                                    this.fetchRegistration("pending_payment");
                                                }, 0);
                                            }}
                                            loading={this.state.table_loading}
                                            fetchError={this.state.tableError}
                                            dateRange={['startdate', 'enddate']}
                                            dateRangeLabel='Date Range'
                                            csv={() => ({
                                                name: 'registrations',
                                                query: {...this.state.query, ...this.state.filter},
                                            })}
                                        />
                                    </div>
                                </Route>
                                <Route
                                    permission='all'
                                    path={`/registration-requests/pending_kyc`}
                                    title='2. PendingKYC'>
                                    <div className='p-4'>

                                        <Table
                                            search={['msisdn', 'id_number']}
                                            // sort="created_at"
                                            // sortDirection={-1}
                                            data={this.state.tableData}
                                            fetch={(params) => {
                                                this.setState({query: {...this.state.query, ...params}});
                                                setTimeout(() => {
                                                    this.fetchRegistration("pending_kyc");
                                                }, 0);
                                            }}
                                            loading={this.state.table_loading}
                                            fetchError={this.state.tableError}
                                            dateRange={['startdate', 'enddate']}
                                            dateRangeLabel='Date Range'
                                            csv={() => ({
                                                name: 'registrations',
                                                query: {...this.state.query, ...this.state.filter},
                                            })}
                                        />
                                    </div>
                                </Route>
                                <Route
                                    permission='all'
                                    path={`/registration-requests/pending_approval`}
                                    title='3. PendingApproval'>
                                    <div className='p-4'>
                                        <Table
                                            search={['msisdn', 'id_number']}
                                            // sort="created_at"
                                            // sortDirection={-1}
                                            data={this.state.tableData}
                                            fetch={(params) => {
                                                this.setState({query: {...this.state.query, ...params}});
                                                setTimeout(() => {
                                                    this.fetchRegistration("pending_approval");
                                                }, 0);
                                            }}
                                            loading={this.state.table_loading}
                                            fetchError={this.state.tableError}
                                            dateRange={['startdate', 'enddate']}
                                            dateRangeLabel='Date Range'
                                            csv={() => ({
                                                name: 'registrations',
                                                query: {...this.state.query, ...this.state.filter},
                                            })}
                                        />
                                    </div>
                                </Route>
                                <Route
                                    permission='all'
                                    path={`/registration-requests/success`}
                                    title='4. Success'>
                                    <div className='p-4'>
                                        <Table
                                            search={['msisdn', 'id_number']}
                                            // sort="created_at"
                                            // sortDirection={-1}
                                            data={this.state.tableData}
                                            fetch={(params) => {
                                                this.setState({query: {...this.state.query, ...params}});
                                                setTimeout(() => {
                                                    this.fetchRegistration("success");
                                                }, 0);
                                            }}
                                            loading={this.state.table_loading}
                                            fetchError={this.state.tableError}
                                            dateRange={['startdate', 'enddate']}
                                            dateRangeLabel='Date Range'
                                            csv={() => ({
                                                name: 'registrations',
                                                query: {...this.state.query, ...this.state.filter},
                                            })}
                                        />
                                    </div>
                                </Route>
                                <Route
                                    permission='all'
                                    path={`/registration-requests/failures`}
                                    title='5. Failures'>
                                    <div className='p-4'>

                                        <Table
                                            search={['msisdn', 'id_number']}
                                            // sort="created_at"
                                            // sortDirection={-1}
                                            data={this.state.tableData}
                                            fetch={(params) => {
                                                this.setState({query: {...this.state.query, ...params}});
                                                setTimeout(() => {
                                                    this.fetchRegistration("failed");
                                                }, 0);
                                            }}
                                            loading={this.state.table_loading}
                                            fetchError={this.state.tableError}
                                            dateRange={['startdate', 'enddate']}
                                            dateRangeLabel='Date Range'
                                            csv={() => ({
                                                name: 'registrations',
                                                query: {...this.state.query, ...this.state.filter},
                                            })}
                                        />
                                    </div>
                                </Route>

                            </Tabs>
                </div>

                <Modal
                    visible={this.state.moreDetailsModal}
                    width={1000}
                    close={() => this.setState({moreDetailsModal: false})}>
                    <div className='align-items-center'>
                        <UserCheck className='mr-3'></UserCheck>
                        <h5 className='m-0 mt-1'>Registration Request Advance View</h5>
                    </div>

                    <div className='mt-3'>
                        <table className='table table-striped table-hover'>
                            <thead>
                            <th>Field</th>
                            <th>Data</th>
                            </thead>

                            {Object.keys(this.state.moreDetailsData).map((d, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='text-capitalize'>
                                            {d}
                                        </td>
                                        <td>{this.state.moreDetailsData[d] ? typeof this.state.moreDetailsData[d] !== "object" ? this.state.moreDetailsData[d] : (
                                            <button
                                                className='btn btn-sm btn-outline-success font-weight-bold'
                                                onClick={() => this.setState({json: this.state.moreDetailsData[d]})}>
                                                View JSON
                                            </button>
                                        ) : '-'}</td>
                                    </tr>

                                );
                            })}

                        </table>


                    </div>

                </Modal>

                {this.state.json && (
                    <Modal
                        visible={true}
                        close={() => {
                            this.setState({json: null});
                        }}>
                        <ReactJson
                            displayDataTypes={false}
                            displayObjectSize={false}
                            src={this.state.json}
                        />
                    </Modal>
                )}
            </div>
        );
    }


    updateItem = (id,data) => {
        window.alert2.show({
            loader: true,
            title: 'Executing Action. Please Wait',
            buttons: false,
        });

        window.app
            .service('registration')
            .patch(id,data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Received And Queue',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/registration-requests/');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <Info size={60}></Info>,
                    title: 'Info',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/registration-requests/');
                    },
                });
            });
    };


    fetchRegistration = (tab_tag) => {

        let tg={
            "success":{$and:[{status:3},{registration_status:{$in:[4,6,8]}}]},
            "failed":{$or:[{status:-1},{registration_status:{$in:[5,7]}}]},
            "pending_payment":{status:0,registration_status:{$lte:3}},
            "pending_approval":{status:3,registration_status:1},
            "pending_kyc":{status:{$in:[1,2,-1]},registration_status:{$lte:3}},

            
        }
        let tab_filter = tg[tab_tag]||{}



        let {query, filter} = this.state;
        let q = {...query, ...filter};

        this.setState({table_loading: true});

        let app_status_map = {
            '0': <code>AWAITING_PAYMENT</code>,
            '1': <code>PAYMENT_DONE. PENDING KYC VALIDATION</code>,
            '2': <code>KYC_VALIDATION_REQUEUED</code>,
            '3': <code>KYC_VALIDATION_SUCCESS</code>,
            '-1': <code>KYC_VALIDATION_FAILED</code>,
        };


            let app_registration_status_map = {
            '3':  <span className='badge badge-primary'>0/2- PENDING_REGISTRATION</span>,
            '4':  <span className='badge badge-success'>1/2- MEMBER_CREATED_SUCCESSFULLY</span>,
            '5':  <span className='badge badge-danger'>Member Creation failed</span>,
            '6':  <span className='badge badge-success'>2/3- Member Approved</span>,
            '7':  <span className='badge badge-danger'>Approval Failed</span>,
            '8':  <span className='badge badge-success'>3/3- REGISTRATION SUCCESS</span>,
        };

        window.app
            .service('registration')
            .find({
                query: {
                    ...q,
                    ...tab_filter,
                    msisdn: this.props.user?.phone_number,

                },
            })
            .then((response) => {

                response.data = response.data.map((d) => {
                    return {
                        id: d.id,
                        member_id: d.member_id,
                        msisdn: d.msisdn,
                        id_number: d.id_number,
                        KycStatus: app_status_map[d.status],
                        RegistrationStatus: d.status === '3' && parseInt(d.registration_status) <3 ?

                            (
                                <a
                                    className='btn btn-sm btn-round btn-dark'
                                    href={"#"}
                                    onClick={() => {
                                        this.updateItem(d.id,{registration_status: 3});

                                    }}
                                >
                                    Approve Registration
                                </a>
                            ) : d.status !== '3' ?
                                (
                                    <p>
                                        AWAITING KYC/PAYMENT VALIDATION
                                    </p>
                                ) : (<>
                                    <code>
                                        {app_registration_status_map[d.registration_status] }
                                    </code>
                                        <p>{d.registration_response}</p>
                                    </>
                                ),
                        date_created: moment(d.date_created).utc().format('YYYY-MM-DD HH:mm:ss'),
                        actions: (
                            <div>
                                <a
                                    className='btn btn-sm btn-round btn-outline-dark'
                                    href={`https://portalapi.kenyabankers.coop/api-internal/action/generate_pdf/${"kbs_application_form_"+d.msisdn+"_"+d.id_number}/${d.id}`}
                                >
                                    Generate Report
                                </a>
                                &nbsp;
                                <a
                                    className='btn btn-sm btn-round btn-outline-dark'
                                    href={"#"}
                                    onClick={() => {
                                        this.setState({moreDetailsModal: true, moreDetailsData: d});
                                    }}
                                >
                                    View Data
                                </a>
                            </div>

                        ),

                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Registration;

import React, {Component} from 'react';
import Nav from '../components/Nav';

class Dashboards extends Component {
    state = {
        panels: {
            tp10: {
                name: 'Top 10',
                graphs: [
                    {
                        name: 'top',
                        size: "col-md-12",
                        // height: "200",
                        url:
                            'http://bi.kenyabankers.coop/superset/explore/?form_data_key=pmsBQevpBFQvWOq-sV4Zpe8vxnVdktTbn6kIAqS8sQ2oERx8QIGDI9U2Hib1ThNs&?standalone=true',
                    },

                    {
                        name: 'Deposits',
                        size: "col-md-12",
                        // height: "200",
                        url:
                            'http://bi.kenyabankers.coop/superset/dashboard/2/?standalone=true',
                    }
                ],
            },

            activity: {
                name: 'Activity',
                graphs: [],
            },

            aml: {
                name: 'AML Analytics',
                graphs: [],
            },
        },
        activePanel: 0,
    };

    render() {
        return (
            <div>
                <Nav name={this.state.panels[this.props.match.params.type].name}></Nav>
                <div className='row p-4'>
                    {this.state.panels[this.props.match.params.type].graphs.map(
                        (d, i) => (
                            <div className={d.size ? d.size : 'col-md-6 my-3'} key={i}>
                                <div className='card bg-white p-3'>
                                    <iframe
                                        title='123'
                                        src={d.url + '&theme=light'}
                                        className='w-100'
                                        height={d.height ? d.height == -1 ? "auto" : d.height : "400"}
                                        frameBorder={0}
                                    ></iframe>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        );
    }
}

export default Dashboards;

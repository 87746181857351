import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from '../../components/filter';

class Deposits extends Component {
    state = {
        tableData: {data: []},
        tableData2: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        table_loading2: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Deposits'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[]}
                            dateRangeLabel='Transaction Time'
                            dateColumn={"dateCreated"}
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchDeposits();
                                }, 0);
                            }}
                        />

                        <Table
                            search={['msisdn', 'name', 'tranId', 'accountNo']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData2}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchDeposits();
                                }, 0);
                            }}
                            loading={this.state.table_loading2}
                            fetchError={this.state.tableError2}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchDeposits = () => {
        this.setState({table_loading2: true});

        let {query, filter} = this.state;
        let q = {...query, ...filter};

        if (q.startdate) {
            q.tranTime = {
                $gte: q.startdate,
                $lte: q.enddate,
            };
            delete q.startdate;
            delete q.enddate;
        }

        q.member_id = this.props.user;

        window.app
            .service('c2b-confirmation')
            .find({
                query: {
                    ...q,
                    msisdn: this.props.msisdn,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        // id: d.id,
                        name: d.name,
                        'Phone Number': d.msisdn,
                        transaction_id: d.tranId,
                        account_No: d.accountNo,
                        business_ShortCode: d.businessShortCode,
                        amount: (
                            <span className='no-wrap'> KES {d.amount.toLocaleString()}</span>
                        ),
                        balance: (
                            <span className='no-wrap'> KES {d.balance.toLocaleString()}</span>
                        ),
                        transaction_time: moment(d.tranTime).utc().format('YYYY-MM-DD HH:mm:ss'),
                        description: d.description,
                        // dateModified: d.dateModified,
                        // status: d.status,
                        status: (
                            <span
                                className={`badge badge-${
                                    window.global_status[d.status].theme
                                }`}>
                {window.global_status[d.status].status_description}{' '}
              </span>
                        ),
                        date_Created: moment(d.dateCreated).utc().format('YYYY-MM-DD HH:mm:ss'),
                    };
                });

                this.setState({tableData2: response, table_loading2: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Deposits;

import React, {Component} from 'react';
import {Link, Route} from 'react-router-dom';
// import Details from "./clients/details";
import {CheckCircle, Edit, FileText, Info, RefreshCw, XCircle,} from 'react-feather';
import Tabs from '../../components/tabs';
import UserSummarry from './summarry';
import Transactions from '../finance/transactions';
import Withdrawals from '../finance/withdrawals';
import Dividends from '../dividends/dividends';
import Orders from '../orders';
import Deposits from '../finance/deposits';
import Dbqueue from '../sms/dbqueue';
import Access from '../../components/accessManager';
import AuditTrail from '../audit/audit';
import PesaLink from "../finance/pesalink";
import Loans from "../loans/loans";

class CustomerDetails extends Component {
    state = {modalVisible: false};

    render() {
        let user = [];

        return (
            <div className='bg-light'>
                <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
                    <div className='text-mute pt-3 pl-3'>
                        <small className='text-mute'>Users &gt; View</small>
                    </div>

                    <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
                        <div className='d-md-flex flex-row align-items-center'>
                            <div
                                className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                <span className='initials'>
                  {this.state.first_name && this.state.first_name[0]}
                </span>
                                <img
                                    src={`${window.fileserver}${this.state.avatar}`}
                                    className='avatar'
                                    alt=''
                                />
                            </div>
                            <div className='ml-md-4 my-3 my-md-0'>
                                <h4 className='text-capitalize'>
                                    {this.state.first_name} {this.state.other_name}
                                </h4>
                                <div>{this.state.msisdn}</div>
                                <div className='ml-2 mt-1'>
                                    <span className='badge badge-secondary px-2'>Customer</span>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row flex-wrap member-view '>
                            <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>
                                <Access permission='CUSTOMER_PATCH'>
                                    <Link
                                        to={`/customer/edit/${this.state.id}`}
                                        className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-warning btn-round mr-3'>

                                        <Edit size={18}/>
                                        <span className='pl-1 font-weight-bold'>
                        Customer Update Actions
                      </span>
                                    </Link>
                                </Access>

                                {this.state.id && (
                                    <Access permission='STANDING_ORDERS_CREATE'>
                                        <Link
                                            to={`/order-create/${this.state.id}/${this.state.member_id}/${this.state.msisdn}`}
                                            className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3'>
                                            <FileText size={18}/>
                                            <span className='pl-1 font-weight-bold'>
                        Create Standing Order{' '}
                      </span>
                                        </Link>
                                    </Access>
                                )}

                                {this.state.id && (
                                    <Access permission='WITHDRAWALS_RECONCILE'>
                                        <button
                                            onClick={() => {
                                                if (
                                                    !window.confirm(
                                                        'Are you sure that you want to Reconcile all Withdrawals?'
                                                    )
                                                )
                                                    return false;

                                                this.updateWithdrawals();
                                            }}
                                            className='option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3'>
                                            <RefreshCw size={18}/>
                                            <span className='pl-1 font-weight-bold'>
                        Reconcile Withdrawals
                      </span>
                                        </button>
                                    </Access>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-relative px-3'>
                    {this.state.member_id && (
                        <Tabs>
                            <Route
                                permission='all'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/details`}
                                title='Details'>
                                <UserSummarry data={this.state}></UserSummarry>
                            </Route>

                            <Route
                                permission='TRANSACTIONS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/transactions`}
                                title='Transactions'>
                                <Transactions user={this.state.member_id}></Transactions>
                            </Route>

                            <Route
                                permission='WITHDRAWALS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/withdrawals`}
                                title='Withdrawals'>
                                <Withdrawals user={this.state.member_id}></Withdrawals>
                            </Route>

                            {/*<Route*/}
                            {/*    permission='all'*/}
                            {/*    path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/pesalink`}*/}
                            {/*    title='PesaLink'>*/}
                            {/*    <PesaLink data={this.state}></PesaLink>*/}
                            {/*</Route>*/}

                            <Route
                                permission='DEPOSITS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/deposits`}
                                title='Deposits'>
                                <Deposits msisdn={this.state.msisdn}></Deposits>
                            </Route>

                            <Route
                                permission='LOANS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/loans`}
                                title='Loans'>
                                <Loans user={this.state.member_id}></Loans>
                            </Route>
                            <Route
                                permission='DIVIDENDS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/dividends`}
                                title='Dividends'>
                                <Dividends user={this.state.member_id}></Dividends>
                            </Route>

                            <Route
                                permission='STANDING_ORDERS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/standing-orders`}
                                title='Standing Orders'>
                                <Orders user={this.state.member_id}></Orders>
                            </Route>

                            <Route
                                permission='SMS_READ'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/sms`}
                                title='SMS'>
                                <Dbqueue msisdn={this.state.msisdn}></Dbqueue>
                            </Route>


                            <Route
                                permission='all'
                                path={`/customers/by_id/${this.props.match.params.id || this.state.id || this.props.match.params.member_id}/audit`}
                                title='AuditTrail'>
                                <AuditTrail data={this.state}></AuditTrail>
                            </Route>

                            <Route
                                permission='all'
                                path={`/customers/by_member_id/${this.state.member_id}/details`}
                                title='Details By MemberID'>
                                <UserSummarry data={this.state}></UserSummarry>
                            </Route>

                        </Tabs>
                    )}
                </div>

            </div>
        );
    }

    componentDidMount = () => {
        this.fetchCustomer();
    };

    fetchCustomer = () => {

        if (this.props.match.params.id) {
            window.app
                .service('customers')
                .get(this.props.match.params.id)
                .then((response) => {
                    this.setState(response);
                });

        } else {
            window.app
                .service('customers')
                .find({query: {member_id: this.props.match.params.member_id}})
                .then((response) => {

                    if(response.data.length>0){
                        this.setState(response.data[0]);

                    }else{
                        this.setState({});
                    }
                });

        }
    };

    updateCustomer = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Updating Customer ...',
            buttons: false,
        });

        window.app
            .service('customers')
            .patch(this.state.id, {...data, ...this.state})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    msisdn: this.state.msisdn,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/customers/' + response.id + '/details');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                console.log(err);
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}></Info>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

    updateWithdrawals = () => {
        window.alert2.show({
            loader: true,
            title: 'Reconciling ...',
            buttons: false,
        });

        window.app
            .service('recon')
            .create({})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Reconciled Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };
}

export default CustomerDetails;

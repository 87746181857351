import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
    ChevronDown,
    ChevronUp,
    Code,
    CreditCard,
    DollarSign,
    FileText,
    Grid,
    LogOut,
    Menu as MenuBars,
    MessageSquare,
    Shield,
    Smartphone,
    UserCheck,
    Users,
} from 'react-feather';
import Access from './accessManager';
// import config from '../config';
import user from '../img/user.svg';

class Menu extends Component {
    state = {
        title: 'Kenya Bankers',
        menu: [
            {
                name: 'Dashboard',
                icon: <Grid size={20}/>,
                link: '/',
                access: 'all',
                children: [
                    {
                        name: 'Home',
                        link: '/home',
                        access: 'all',
                    },
                    {
                        name: 'Top 10',
                        link: '/dashboards/tp10',
                        access: 'DASHBOARD_TP10_READ',
                    },

                    {
                        name: 'Activity',
                        link: '/dashboards/activity',
                        access: 'DASHBOARD_ACTIVITY_READ',
                    },

                    {
                        name: 'AML Analytics',
                        link: '/dashboards/aml',
                        access: 'DASHBOARD_AML_READ',
                    },
                ],
            },
            {
                name: 'Customers',
                icon: <Users size={20}/>,
                link: '/customers',
                access: 'CUSTOMER_READ',
                children: [
                    {
                        name: 'Customer Records',
                        icon: <Users size={20}/>,
                        link: '/customers',
                        access: 'CUSTOMER_READ'
                    },
                    {
                        name: 'Registration Requests',
                        icon: <Users size={20}/>,
                        link: '/registration-requests/all',
                        access: 'CUSTOMER_READ'
                    },
                    {
                        name: 'Card Records',
                        icon: <Users size={20}/>,
                        link: '/card_records',
                        access: 'CARD_READ'
                    },


                ]
            },
            {
                name: 'Accounting',
                icon: <Smartphone size={20}/>,
                link: '/loans',
                access: 'LOANS_READ',
                children: [
                    {
                        name: 'Loans',
                        icon: <DollarSign size={20}/>,
                        link: '/loans',
                        access: 'LOANS_READ',
                    },
                    {
                        name: 'Dividends',
                        icon: <DollarSign size={20}/>,
                        link: '/dividends',
                        access: 'DIVIDENDS_READ',
                    },
                ]
            },


            {
                name: 'Payments',
                icon: <Smartphone size={20}/>,
                link: '/payments',
                access: 'WITHDRAWALS_READ',
                children: [
                    {
                        name: 'All Withdrawals',
                        link: '/payments/withdraws',
                        access: 'WITHDRAWALS_READ',
                    },

                    {
                        name: 'Mpesa-Results',
                        link: '/payments/mpesa-results',
                        access: 'WITHDRAWALS_READ',
                    },

                    {
                        name: 'Pesalink-Results',
                        link: '/payments/pesalink',
                        access: 'WITHDRAWALS_READ',
                    },

                    {
                        name: 'Deposits',
                        link: '/payments/deposits',
                        access: 'DEPOSITS_READ',
                    }
                ],
            },
            {
                name: 'Transactions',
                icon: <CreditCard size={20}/>,
                link: '/trs',
                access: 'TRANSACTIONS_READ',
                children: [
                    {
                        name: 'Transactions',
                        link: '/transactions',
                        access: 'TRANSACTIONS_READ',
                    },

                    {
                        name: 'Merchant Transactions',
                        link: '/merchant-transactions',
                        access: 'MERCHANT_TRANSACTIONS_READ',
                    },
                ],
            },
            {
                name: 'Orders',
                icon: <FileText size={20}/>,
                link: '#',
                access: 'STANDING_ORDERS_READ',
                children: [
                    {
                        name: 'Standing Orders',
                        link: '/standing_orders',
                        access: 'STANDING_ORDERS_READ',
                    },

                    {
                        name: 'All Orders',
                        link: '/orders',
                        access: 'STANDING_ORDERS_READ',
                    },
                ],
            },
            {
                name: 'IP Adresses',
                icon: <Code size={20}/>,
                link: '/ip',
                access: 'IP_ADDRESS_READ',
            },
            {
                name: 'SMS',
                icon: <MessageSquare size={20}/>,
                link: '/sms-list',
                access: 'SMS_READ',
            },

            {
                name: 'Audit',
                icon: <UserCheck size={20}/>,
                link: '/audit',
                access: 'AUDIT_READ',
                // children: [
                //   {
                //     name: 'Audit data',
                //     link: '/audit-data',
                //     access: 'TRANSACTIONS_READ',
                //   },
                //   {
                //     name: 'Audit entry',
                //     link: '/audit-entry',
                //     access: 'TRANSACTIONS_READ',
                //   },
                //   {
                //     name: 'Audit error',
                //     link: '/audit-error',
                //     access: 'TRANSACTIONS_READ',
                //   },
                //   {
                //     name: 'Audit javascript',
                //     link: '/audit-javascript',
                //     access: 'TRANSACTIONS_READ',
                //   },
                //   {
                //     name: 'Audit mail',
                //     link: '/audit-mail',
                //     access: 'TRANSACTIONS_READ',
                //   },
                //   {
                //     name: 'Audit Trail',
                //     link: '/audit',
                //     access: 'TRANSACTIONS_READ',
                //   },
                // ],
            },
            {
                name: 'Administration',
                icon: <Shield size={20}/>,
                link: '/admins',
                access: 'ADMIN_READ',
                children: [
                    {
                        name: 'Admins',
                        link: '/admins',
                        access: 'ADMIN_READ',
                    },
                    {
                        name: 'Maker Checker',
                        link: '/maker-checker',
                        access: 'MAKER_CHECKER_READ',
                    },
                    {
                        name: 'Roles',
                        link: '/roles',
                        access: 'ROLES_READ',
                    },
                ],
            },
        ],
        currentRoute: window.location.pathname,
        active: 0,
    };

    render() {
        return (
            <>
                <div
                    className='p-3 top-header d-md-none align-items-center d-flex flex-row bg-light w-100 text-dark shadow'>
                    <MenuBars
                        className='mr-3'
                        onClick={() => {
                            this.setState({showMenu: true});
                        }}></MenuBars>
                    <div className='font-weight-bold top-bar-text'>
                        {this.state.title}
                    </div>
                </div>
                <div
                    className={'menu-overlay ' + (this.state.showMenu ? 'show' : '')}
                    onClick={() => {
                        this.setState({showMenu: false});
                    }}></div>
                <div
                    className={
                        'sidebar-wrapper border-right shadow ' +
                        (this.state.showMenu ? 'show' : '')
                    }
                    onClick={() => {
                        this.setState({showMenu: false});
                    }}>
                    <ul
                        className='navbar-nav bg-light sidebar sidebar-dark accordion position-relative'
                        id='accordionSidebar'>
                        <div>
                            <div className='d-flex flex-column user-login-card p-3 my-4 align-items-start'>
                                <div className='d-flex flex-row align-items-start justify-content-between w-100'>
                                    <div className='user-icon-parent'>
                                        <img src={user} className='user-icon' alt=''/>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <div className='action-icon'>
                                            {/* <Settings size={20}></Settings> */}
                                        </div>

                                        <div
                                            className='action-icon'
                                            onClick={() => window.logout()}>
                                            <LogOut size={20}></LogOut>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3 text-capitalize'>
                                    {window.user.admin.name}
                                </div>
                                <div>
                                    <small className='font-weight-bold'>| Super Admin</small>
                                </div>
                            </div>
                        </div>

                        <div className='sidebar-heading text-dark mb-2 text-muted'>
                            pages :
                        </div>
                        {this.state.menu.map((d, i) => {
                            return (
                                <li
                                    className={
                                        'nav-item ' +
                                        (this.state.active === i && d.children
                                            ? 'pb-4 no-hover '
                                            : '') +
                                        (this.state.currentRoute === d.link
                                            ? 'active'
                                            : 'text-dark')
                                    }
                                    key={i}>
                                    <Access permission={d.access}>
                                        {' '}
                                        <Link
                                            to={d.link}
                                            onClick={(e) => {
                                                if (this.state.active === i) {
                                                    this.setState({active: 0});
                                                } else {
                                                    this.setState({active: i});
                                                }
                                                if (d.children) e.preventDefault();
                                            }}
                                            className={
                                                'nav-link ' +
                                                (this.state.currentRoute === d.link
                                                    ? 'active'
                                                    : 'text-dark')
                                            }>
                                            <div className='d-flex flex-row align-items-center justify-content-between'>
                                                <div className='d-flex flex-row align-items-center'>
                                                    {d.icon}
                                                    <div className='text-dark ml-2 font-weight-bold link-text'>
                                                        {d.name}
                                                    </div>
                                                </div>
                                                {d.children && (
                                                    <div>
                                                        {this.state.active !== i && (
                                                            <ChevronDown size={18}></ChevronDown>
                                                        )}
                                                        {this.state.active === i && (
                                                            <ChevronUp size={18}></ChevronUp>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Link>
                                    </Access>
                                    {this.state.active === i && d.children && (
                                        <div
                                            id='collapseUtilities'
                                            className='collapse show shadow-sm'
                                            aria-labelledby='headingUtilities'
                                            data-parent='#accordionSidebar'>
                                            <div className=' py-2 collapse-inner rounded mb-0'>
                                                {/* <h6 className='collapse-header'>listing:</h6> */}
                                                {d.children.map((d1, i1) => {
                                                    if (d1.type === 'hr')
                                                        return (
                                                            <>
                                                                <hr className={`${!d1.title || 'mb-1'}`}/>
                                                                {d1.title && (
                                                                    <div>
                                                                        <small
                                                                            className='text-muted text-uppercase font-weight-bold px-4'>
                                                                            {d1.title}
                                                                        </small>
                                                                    </div>
                                                                )}
                                                            </>
                                                        );

                                                    return (
                                                        <Access permission={d1.access} key={i1}>
                                                            <Link
                                                                className={
                                                                    'collapse-item font-weight-bold my-1 text-capitalize ' +
                                                                    (this.state.currentRoute === d1.link
                                                                        ? 'active'
                                                                        : 'text-dark')
                                                                }
                                                                to={d1.link}
                                                                key={i1}>
                                                                {d1.name}
                                                            </Link>
                                                        </Access>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                        {/*  */}
                        {/* <li className='nav-item'>
            <button
              className='nav-link d-flex flex-row align-items-center bg-transparent btn'
              onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  localStorage.clear();
                  this.props.history.push("/login");
                }
              }}>
              <LogOut color='white' size={18} />
              <span className='text-white ml-2'>Logout</span>
            </button>
          </li> */}
                    </ul>
                </div>
            </>
        );
    }

    componentWillReceiveProps(props) {
        if (props.history) {
            //   console.log(props.history);
            this.setState({currentRoute: window.location.pathname});
        }
    }
}

export default withRouter(Menu);

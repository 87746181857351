import React, {Component} from 'react';
import Table from '../../components/Table';
import {Link} from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Access from '../../components/accessManager';

class Admins extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Admins'
                    buttons={[
                        {
                            text: 'Create an Admin',
                            link: '/admin-create',
                        },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Table
                            // search={['firstname', 'lastname']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchAdmins();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchAdmins = () => {
        this.setState({table_loading: true});

        window.app
            .service('admins')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        name: d.name,
                        email: d.email,
                        group: d.group.name,

                        '2FA_Channel': `${d.secondFactor_selected} - ${d.addOnData}`,
                        //secondFactor_status: d.secondFactor_status,
                        '2FA_Status': ({
                            0: (<span className={"badge badge-success"}>Enabled</span>),
                            1: (<span className={"badge badge-danger"}>Disabled</span>)
                        })[d.secondFactor_status],

                        Date:
                            d.createdAt && moment(d.createdAt).utc().format('YYYY-MM-DD HH:mm:ss'),
                        action: (
                            <Access permission='ADMIN_CREATE'>
                                <Link className='btn btn-primary' to={`/admins/edit/${d.id}`}>
                                    Edit
                                </Link>
                            </Access>
                        ),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Admins;

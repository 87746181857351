import React, {Component} from 'react';
import {CheckCircle, UserCheck, Users,} from 'react-feather';
import Modal from '../../components/modal';
import Nav from '../../components/Nav';
import Form from '../../components/form';

class Roles extends Component {
    state = {
        groups: [],
        roles: [],
        groupRoles: [],
        createdRoles: [],
        deletedRoles: [],
        addModal: false,
        groupModal: false,
    };
    timeout = null;
    updateCount = 1;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Groups'
                    buttons={[
                        {
                            text: 'Add a group',
                            onClick: () => {
                                this.setState({groupModal: true});
                            },
                            access: 'GROUP_ADD',
                        },
                        {
                            text: 'Add a role',
                            onClick: () => {
                                this.setState({addModal: true});
                            },
                            access: 'ROLE_ADD',
                        },
                    ]}></Nav>

                <div className='mt-3 mb-5 table-card p-2 border-0 card shado mx-3 shadow'>
                    {this.state.groups.length > 0 &&
                    this.state.roles.length > 0 &&
                    this.state.groupRoles.length > 0 &&
                    this.state.groups.map((d, i) => (
                        <div className='card role-card m-3 bg-transparent' key={i}>
                            <div className='card-header  d-flex flex-row align-items-center bg-light'>
                                <Users size={20} className='mr-2'></Users>{' '}
                                <span className='font-weight-bold group-name'>{d.name}</span>
                            </div>
                            <div className='card-content p-3'>
                                <div className='row'>
                                    {this.state.roles.map((d1, i1) => {
                                        return (
                                            <div className='col-md-3' key={i1}>
                                                <label>
                                                    <input
                                                        className='styled-checkbox'
                                                        id={d1.code + i}
                                                        type='checkbox'
                                                        value='value1'
                                                        onChange={() => {
                                                            let {
                                                                groupRoles,
                                                                createdRoles,
                                                                deletedRoles,
                                                            } = this.state;

                                                            console.log(groupRoles);

                                                            if (!groupRoles[d.id]) groupRoles[d.id] = [];
                                                            console.log(true);

                                                            if (groupRoles[d.id].includes(d1.id)) {
                                                                groupRoles[d.id].splice(
                                                                    groupRoles[d.id].indexOf(d1.id),
                                                                    1
                                                                );

                                                                if (!deletedRoles[d.id])
                                                                    deletedRoles[d.id] = [];

                                                                deletedRoles[d.id].push(d1.id);
                                                            } else {
                                                                groupRoles[d.id].push(d1.id);

                                                                if (!createdRoles[d.id])
                                                                    createdRoles[d.id] = [];

                                                                createdRoles[d.id].push(d1.id);
                                                            }

                                                            this.setState({groupRoles});
                                                        }}
                                                        checked={
                                                            this.state.groupRoles[d.id]
                                                                ? this.state.groupRoles[d.id].includes(d1.id)
                                                                : false
                                                        }
                                                    />
                                                    <label className='text-uppercase' for={d1.code + i}>
                                                        {d1.code.replace(/_/g, ' ')}
                                                    </label>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {(this.state.deletedRoles.length > 0 ||
                    this.state.createdRoles.length > 0) && (
                    <button
                        className='btn btn-primary btn-round pl-3 pr-5 btn-lg font-weight-bold role-update-button'
                        onClick={this.update}>
                        <CheckCircle className='mr-3'></CheckCircle> Update Changes
                    </button>
                )}

                <Modal
                    visible={this.state.addModal}
                    close={() => this.setState({addModal: false})}>
                    <div className='d-flex flex-row align-items-center'>
                        <UserCheck className='mr-3'></UserCheck>
                        <h5 className='m-0 mt-1'>Create a Role</h5>
                    </div>
                    <div className='mt-3'>
                        <Form
                            inputs={[
                                {
                                    label: 'Role Name',
                                    name: 'name',
                                    type: 'text',
                                    value: '',
                                },
                                {
                                    label: 'Role Code',
                                    name: 'code',
                                    type: 'text',
                                    value: '',
                                },
                            ]}
                            submit={(data) => {
                                this.createTimRole(data);
                            }}
                        />
                    </div>
                </Modal>

                <Modal
                    visible={this.state.groupModal}
                    close={() => this.setState({groupModal: false})}>
                    <div className='d-flex flex-row align-items-center'>
                        <Users className='mr-3'></Users>
                        <h5 className='m-0 mt-1'>Create a Group</h5>
                    </div>
                    <div className='mt-3'>
                        <Form
                            inputs={[
                                {
                                    label: 'Group Name',
                                    name: 'name',
                                    type: 'text',
                                    value: '',
                                },
                            ]}
                            submit={(data) => {
                                this.createGroup(data);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }

    componentDidMount() {
        this.fetchRoles();
    }

    get = (model, callback, filter) => {
        window.app
            .service(model)
            .find({
                query: {
                    $limit: 250,
                    $skip: 0,
                    ...(filter ? filter : {}),
                },
            })
            .then((response) => {
                callback(response);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    fetchRoles = () => {
        this.get('admin-groups', (groups) => {
            this.setState({groups: groups.data});
        });

        this.get(
            'admin-roles',
            (roles) => {
                // console.log(roles);
                this.setState({roles: roles.data});
            },
            {
                $sort: {
                    code: 1,
                },
            }
        );

        this.get('admin-group-roles', (roles) => {
            console.log(roles);
            roles = roles.data;
            let groupRoles = [];
            roles.map((d) => {
                if (!groupRoles[d.group_id]) groupRoles[d.group_id] = [];
                groupRoles[d.group_id].push(d.role_id);
            });

            this.setState({groupRoles, initialRoles: roles});
        });
    };

    update = () => {
        let {initialRoles, deletedRoles, createdRoles} = this.state;

        // delete group roles

        let rawroles = initialRoles.filter((d1) => {
            return (
                deletedRoles[d1.group_id] &&
                deletedRoles[d1.group_id].includes(d1.role_id)
            );
        });
        let roles = rawroles.map((d) => {
            this.deleteRole(d.id);
            return d.id;
        });

        // console.log(roles);

        createdRoles.map((d, i) => {
            d.map((d1) => {
                this.createRole(i, d1);
            });
        });

        // create group roles
    };

    createRole = (group_id, role_id) => {
        window.app
            .service('admin-group-roles')
            .create({group_id, role_id})
            .then((response) => {
                console.log(response);
                this.verifyComplete();
            })
            .catch((d) => {
                console.log('Error saving the data');
                console.log(d);
                this.setState({modalVisible: false});
                // window.location.reload();
            });
    };

    deleteRole = (id) => {
        // console.log('deleting role ...');
        // fetch(`${window.server}/admin-group-roles/${id}`, {
        //   method: 'DELETE',
        //   headers: {
        //     Authorization: localStorage.token,
        //     'Content-Type': 'application/json',
        //   },
        // })

        window.app
            .service('admin-group-roles')
            .remove(id)
            .then((response) => {
                console.log(response);

                this.verifyComplete();
            })
            .catch((d) => {
                console.log('Error saving the data');
                console.log(d);
                this.setState({modalVisible: false});

                // window.location.reload();
            });
    };

    verifyComplete = () => {
        // this.fetchRoles();
        this.setState({createdRoles: [], deletedRoles: []});

        let total = 0;
        this.state.createdRoles.map((d) => {
            total += d.length;
        });
        this.state.deletedRoles.map((d) => {
            total += d.length;
        });

        if (this.updateCount === total) {
            window.alert2.notify({
                title: 'Updated Successfully',
            });
            this.fetchRoles();
            this.setState({createdRoles: [], deletedRoles: []});
        }
        // window.location.reload();
    };

    createTimRole = (data) => {
        console.log('creating role ...');

        if (!window.confirm('Are you sure that you want to add this role?')) {
            return;
        }

        // fetch(`${window.server}/admin-roles`, {
        //   method: 'POST',
        //   headers: {
        //     Authorization: localStorage.token,
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify({
        //     name: data.name.toUpperCase(),
        //     code: data.code.toUpperCase(),
        //   }),
        // })

        window.app
            .service('admin-roles')
            .create({
                name: data.name.toUpperCase(),
                code: data.code.toUpperCase(),
            })
            .then((response) => {
                // console.log(response);
                // alert('added');
                this.setState({addModal: false});

                window.alert2.notify({
                    title: 'Role Added Successfully',
                });

                this.fetchRoles();
            })
            .catch((d) => {
                window.alert2.notify({
                    title: 'Error Adding Role',
                    message: d.message,
                });
                console.log('Error saving the data');
                console.log(d);
                this.setState({modalVisible: false});
                // window.location.reload();
            });
    };

    createGroup = (data) => {
        console.log(data);

        window.app
            .service('admin-groups')
            .create(data)
            .then(() => {
                // alert('added');
                this.setState({groupModal: false});
                this.fetchRoles();
                // window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                this.setState({modalVisible: false});
                // window.location.reload();
            });
    };
}

export default Roles;

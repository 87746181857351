import React, {Component} from 'react';
import Table from '../components/Table';
import Nav from '../components/Nav';
import Modal from '../components/modal';
import Form from '../components/form';
import {CheckCircle, XCircle} from 'react-feather';
import moment from 'moment-timezone';

class Card extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Card'
                    buttons={[
                        {
                            text: 'Create a Card',
                            onClick: () => {
                                this.setState({modalVisible: true});
                            },
                        },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Table
                            // search={['firstname', 'lastname']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchCard();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>

                <Modal visible={this.state.modalVisible}>
                    <h5 className='font-weight-bold'>Create IP Address</h5>

                    <hr/>

                    <div className=''>
                        <Form
                            inputs={[
                                {
                                    label: 'MemberId',
                                    name: 'member_id',
                                    type: 'text',
                                },
                                {
                                    label: 'Mobile Number',
                                    name: 'msisdn',
                                    type: 'text',
                                },
                                {
                                    label: 'Savings Account Number',
                                    name: 'saving_account_number',
                                    type: 'text',
                                },
                                {
                                    label: 'Card Number',
                                    name: 'card_number',
                                    type: 'text',
                                },
                                {
                                    label: 'Max Card',
                                    name: 'max_card',
                                    type: 'number',
                                    value: 40000
                                },
                                {
                                    label: 'Status',
                                    name: 'status_id',
                                    type: 'select',
                                    options: [
                                        {
                                            name: 'Enabled',
                                            value: 1,
                                        },
                                        {
                                            name: 'Disabled',
                                            value: 6,
                                        },
                                    ],
                                }
                            ]}
                            submit={(data) => {
                                this.setState({modalVisible: false});
                                this.createCard(data);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }

    fetchCard = () => {
        this.setState({table_loading: true});

        window.app
            .service('card')
            .find({
                query: {
                    ...this.state.query,
                    member_id: this.props.user,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        id: d.id,
                        msisdn: d.msisdn,
                        member_id: d.member_id,
                        saving_account_number: d.saving_account_number,
                        card_number: d.card_number,
                        status_id: d.status_id,
                        max_card: d.max_card,
                        date_issued: d.date_issued,
                        date_deactivated: d.date_deactivated,
                        date_created: moment(d.date_deactivated).utc().format('YYYY-MM-DD HH:mm:ss'),
                        action: d.status_id===1?(
                            <button
                                className='btn btn-sm btn-round btn-outline-danger px-3 mr-3'
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            'Are you sure that you want to disable this card?'
                                        )
                                    )
                                        return false;
                                    this.updateItem(d.id,6);
                                }}>
                                Disable
                            </button>
                        ):(
                            <button
                                className='btn btn-sm btn-round btn-outline-success px-3 mr-3'
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            'Are you sure that you want to Enable this card?'
                                        )
                                    )
                                        return false;
                                    this.updateItem(d.id,1);
                                }}>
                                Enable Card
                            </button>
                        ),
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };

    createCard = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Updating IP ...',
            buttons: false,
        });

        window.app
            .service('card')
            .create(data)
            .then((response) => {
                this.fetchCard();

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}/>,
                    title: 'Created Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },

                });
            });
    };

    updateItem = (id,status_id) => {
        window.alert2.show({
            loader: true,
            title: 'Updating Card...\nPlease wait..',
            buttons: false,
        });

        window.app
            .service('card')
            .patch(id,{status_id})
            .then((response) => {
                this.fetchCard();
                window.alert2.hide();
                window.alert2.notify({
                    title: 'Removed Successfully',
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };
}

export default Card;

import React, {Component} from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import {CheckCircle, Info, XCircle} from 'react-feather';

class CustomerCreate extends Component {
    state = {};

    render() {
        return (
            <div className=''>
                <Nav name='Add Customer'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
                    <div className='order-form'>
                        <Form
                            inputs={[
                                {
                                    label: 'first name',
                                    name: 'first_name',
                                    type: 'text',
                                },
                                {
                                    label: 'other name',
                                    name: 'other_name',
                                    type: 'text',
                                },
                                {
                                    label: 'Phone number',
                                    name: 'msisdn',
                                    type: 'text',
                                },
                                {
                                    label: 'Enter Member ID',
                                    name: 'member_id',
                                    type: 'number'
                                },
                                {
                                    label: 'Enter Fosa Account No',
                                    name: 'saving_account_number',
                                    type: 'text',
                                },
                                {
                                    label: 'Enter Max Send',
                                    name: 'max_send',
                                    type: 'number',
                                    value: 300000
                                },
                                {
                                    label: 'Enter Max Pesalink',
                                    name: 'max_pesalink',
                                    type: 'number',
                                    value: 300000
                                },
                                {
                                    label: 'Enter Max Paybill',
                                    name: 'max_paybill',
                                    type: 'number',
                                    value: 300000
                                }
                            ]}
                            submit={(data) => {
                                this.createCustomer({...data, action: 'REGISTER'});
                            }}
                        ></Form>
                    </div>
                </div>
            </div>
        );
    }

    createCustomer = (data) => {

        window.alert2.show({
            loader: true,
            title: 'Creating Customer ...',
            buttons: false,
        });

        window.app
            .service('customers')
            .create(data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Customer created Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/customers/' + response.id + '/details');
                    },
                });
            })
            .catch((err) => {
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}></Info>,
                        title: 'Customer created Successfully',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });
                    return;
                }
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });
    };
}

export default CustomerCreate;

import React, {Component} from 'react';
import start from '../img/work.svg';
import {DollarSign, Users} from 'react-feather';
import logo_landscape from '../img/bankers_logo_LANDSCAPE.png';
import logo from "../img/bankers_logo.png";

class Home extends Component {
    state = {
        tableData: {
            balances: [],
            users: [],
            transactions: [],
        },
    };

    render() {
        return (
            <div style={{"background-color":'white'}}>
                <div className='mx-auto' >
                    <div className='px-3 my-5' >
                        <div className='d-flex flex-row align-items-center'>
                            <div>
                                <img src={logo_landscape} className='m' alt='' />
                                {/*<div*/}
                                {/*    className='col-lg-6 bg-light d-none default-bg align-items-center justify-content-center flex-row d-md-flex icon-holder'>*/}
                                {/*    <img src={logo_landscape} className='main-logo' alt=''/>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>

                    <div className='page-content px-3 position-relative'>
                        <div className='card py-4 px-5 mt-2 intro-card text-white my-5'>
                            <div className='row'>
                                <div className='col-md-8'>

                                    <h4 className='font-weight-bold intro-title'>
                                        Welcome Back !
                                    </h4>
                                    <div className='mt-3'>
                                        To get started, use the menu on your left. All the general
                                        summaries will be displayed on this page. Any new system
                                        updates will be displayed here.
                                    </div>
                                </div>
                                <div className='col-md-4 flex-row justify-content-center d-md-flex d-none'>
                                    <img src={start} alt='' className='intro-img'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h3 className='font-weight-bold mb-3 styled-card-title text-primary'>
                        Users
                    </h3>
                    <div className='d-flex flex-row align-items-center'>
                        <div className='large-icon-container d-flex flex-row justify-content-end'>
                            <Users
                                size={100}
                                className='large-user-icon text-primary' style={{padding:'20px'}}></Users>
                        </div>
                        <div className='row flex-fill'>
                            {Object.keys(this.state.tableData['users']).map((d, i) => {
                                return (
                                    <div key={i} className='col-md-3 text-center'>
                                        <div className='card shadow py-4 px-2 styled-card'>
                                            <div className='text-capitalize mb-3'>
                                                {d.replace(/\_/g, ' ')}
                                            </div>
                                            <div className='text-capitalize'>
                                                <b>
                                                    <h3 className=' font-weight-bold'>
                                                        {this.state.tableData['users'][d]
                                                            ? `${this.state.tableData['users'][
                                                                d
                                                                ].toLocaleString()}`
                                                            : '-'}
                                                    </h3>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className='mt-5'>
                    <h3 className='font-weight-bold mb-3 styled-card-title text-primary' >
                        Account Balances
                    </h3>
                    <div className='d-flex flex-row align-items-center'>
                        <div className='large-icon-container d-flex flex-row justify-content-end'>
                            <DollarSign
                                size={100}
                                className='large-user-icon text-primary' style={{padding:'20px'}}></DollarSign>
                        </div>
                        <div className='row flex-fill'>
                            {Object.keys(this.state.tableData['balances']).map((d, i) => {
                                return (
                                    <div key={i} className='col-md-4 text-center'>
                                        <div className='card shadow py-4 px-2 styled-card'>
                                            <div className='text-capitalize mb-3'>
                                                {d.replace(/\_/g, ' ')}
                                            </div>
                                            <div className='text-capitalize'>
                                                <b>
                                                    <h3 className=' font-weight-bold'>
                                                        <small>
                                                            <small>KES</small>
                                                        </small>{' '}
                                                        {this.state.tableData['balances'][d]
                                                            ? `${this.state.tableData['balances'][
                                                                d
                                                                ].toLocaleString()}`
                                                            : '-'}
                                                    </h3>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* <div className='page-content px-3 position-relative'>
          <div className='card py-4 px-5 mt-2 intro-card text-white my-5'>
            <div className='row'>
              <div className='col-md-12'>
                <h4 className=' intro-title'>Account Balances</h4>
                <div className=''>
                  <table className='table table-responsive table-borderless table-hover'>
                    {Object.keys(this.state.tableData['balances']).map(
                      (d, i) => {
                        return (
                          <tr key={i}>
                            <td className='text-capitalize text-white'>
                              {d.replace(/\_/g, ' ')}
                            </td>
                            <td className='text-capitalize text-white'>
                              <b>
                                <code className='text-white'>
                                  {this.state.tableData['balances'][d]
                                    ? `Kes ${this.state.tableData['balances'][
                                        d
                                      ].toLocaleString()}`
                                    : '-'}
                                </code>
                              </b>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}

                <div className='p-4'></div>
            </div>
        );
    }

    componentDidMount() {
        // this.updateGraph("Users");
        this.setState({table_loading: true});
        this.fetch('balances');
        this.fetch('users');

        setInterval(() => {
            this.fetch('balances');
            this.fetch('users');
        }, 30000);
    }

    fetch = (type) => {
        window.app
            .service('stats')
            .find({
                query: {
                    type: type,
                },
            })
            .then((response) => {
                // console.table(response)
                let u = {...this.state.tableData};
                u[type] = response;
                this.setState({tableData: u, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Home;

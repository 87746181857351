import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from '../../components/filter';
import {CheckCircle, Info, UserCheck} from "react-feather";
import Modal from "../../components/modal";
import ReactJson from "react-json-view";

class Registration extends Component {
    state = {
        moreDetailsModal: false,
        moreDetailsData: {},
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Registration Requests'
                    buttons={[]}></Nav>
                <div className='mt-3 table-card border-0 card shadow mx-3 shadow'>
                    <div className='p-4'>
                        {!this.props.user && (<Filter

                            filter={[
                                {
                                    label: 'KYC Status',
                                    name: 'status',
                                    options: [
                                        {
                                            label: 'All',
                                            value: null,
                                            default: true,
                                        },
                                        {
                                            label: 'AWAITING_PAYMENT',
                                            value: 0,
                                        },
                                        {
                                            label: 'PAYMENT_DONE. PENDING KYC VALIDATION',
                                            value: 1,
                                        },
                                        {
                                            label: 'KYC_VALIDATION_REQUEUED',
                                            value: 2,
                                        },
                                        {
                                            label: 'KYC_VALIDATION_SUCCESS',
                                            value: 3,
                                        },
                                        {
                                            label: 'KYC_VALIDATION_FAILED',
                                            value: -1,
                                        }


                                    ],
                                },
                                {
                                    label: 'Registration Status',
                                    name: 'registration_status',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },
                                        {
                                            label: 'PENDING_APPROVAL',
                                            value: 1,
                                        },
                                        {
                                            label: 'PENDING_APPROVAL',
                                            value: 2,
                                        },
                                        {
                                            label: '0/2- PENDING_REGISTRATION',
                                            value: 3,
                                        },
                                        {
                                            label: '1/2- MEMBER_CREATED_SUCCESSFULLY',
                                            value: 4,
                                        },
                                        {
                                            label: 'Member Creation failed',
                                            value: 5,
                                        },
                                        {
                                            label: '2/3- Member Approved',
                                            value: 6,
                                        },
                                        {
                                            label: 'Approval Failed',
                                            value: 7,
                                        },
                                        {
                                            label: 'REGISTRATION SUCCESS',
                                            value: 8,
                                        }


                                    ],
                                }

                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchRegistration();
                                }, 0);
                            }}
                        />)}


                        <Table
                            search={['msisdn', 'id_number']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchRegistration();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                            dateRange={['startdate', 'enddate']}
                            dateRangeLabel='Date Range'
                            csv={() => ({
                                name: 'registrations',
                                query: {...this.state.query, ...this.state.filter},
                            })}
                        />
                    </div>
                </div>

                <Modal
                    visible={this.state.moreDetailsModal}
                    width={1000}
                    close={() => this.setState({moreDetailsModal: false})}>
                    <div className='align-items-center'>
                        <UserCheck className='mr-3'></UserCheck>
                        <h5 className='m-0 mt-1'>Registration Request Advance View</h5>
                    </div>

                    <div className='mt-3'>
                        <table className='table table-striped table-hover'>
                            <thead>
                            <th>Field</th>
                            <th>Data</th>
                            </thead>

                            {Object.keys(this.state.moreDetailsData).map((d, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='text-capitalize'>
                                            {d}
                                        </td>
                                        <td>{this.state.moreDetailsData[d] ? typeof this.state.moreDetailsData[d] !== "object" ? this.state.moreDetailsData[d] : (
                                            <button
                                                className='btn btn-sm btn-outline-success font-weight-bold'
                                                onClick={() => this.setState({json: this.state.moreDetailsData[d]})}>
                                                View JSON
                                            </button>
                                        ) : '-'}</td>
                                    </tr>

                                );
                            })}

                        </table>


                    </div>

                </Modal>

                {this.state.json && (
                    <Modal
                        visible={true}
                        close={() => {
                            this.setState({json: null});
                        }}>
                        <ReactJson
                            displayDataTypes={false}
                            displayObjectSize={false}
                            src={this.state.json}
                        />
                    </Modal>
                )}
            </div>
        );
    }


    updateItem = (id,data) => {
        window.alert2.show({
            loader: true,
            title: 'Executing Action. Please Wait',
            buttons: false,
        });

        window.app
            .service('registration')
            .patch(id,data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Received And Queue',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/registration-requests/');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <Info size={60}></Info>,
                    title: 'Info',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('/registration-requests/');
                    },
                });
            });
    };


    fetchRegistration = () => {
        let {query, filter} = this.state;
        let q = {...query, ...filter};

        this.setState({table_loading: true});

        let app_status_map = {
            '0': <code>AWAITING_PAYMENT</code>,
            '1': <code>PAYMENT_DONE. PENDING KYC VALIDATION</code>,
            '2': <code>KYC_VALIDATION_REQUEUED</code>,
            '3': <code>KYC_VALIDATION_SUCCESS</code>,
            '-1': <code>KYC_VALIDATION_FAILED</code>,
        };


            let app_registration_status_map = {
            '3':  <span className='badge badge-primary'>0/2- PENDING_REGISTRATION</span>,
            '4':  <span className='badge badge-secondary'>1/2- MEMBER_CREATED_SUCCESSFULLY</span>,
            '5':  <span className='badge badge-success'>2/2- ACCOUNT_CREATED_SUCCESSFULLY</span>,
            '6':  <span className='badge badge-danger'>REGISTRATION FAILED</span>,
        };

        window.app
            .service('registration')
            .find({
                query: {
                    ...q,
                    msisdn: this.props.user?.phone_number,

                },
            })
            .then((response) => {

                response.data = response.data.map((d) => {
                    return {
                        id: d.id,
                        member_id: d.member_id,
                        msisdn: d.msisdn,
                        id_number: d.id_number,
                        KycStatus: app_status_map[d.status],
                        RegistrationStatus: d.status === '3' && parseInt(d.registration_status) <3 ?

                            (
                                <a
                                    className='btn btn-sm btn-round btn-dark'
                                    href={"#"}
                                    onClick={() => {
                                        this.updateItem(d.id,{registration_status: 3});

                                    }}
                                >
                                    Approve Registration
                                </a>
                            ) : d.status !== '3' ?
                                (
                                    <p>
                                        AWAITING KYC/PAYMENT VALIDATION
                                    </p>
                                ) : (<>
                                    <code>
                                        {app_registration_status_map[d.registration_status] }
                                    </code>
                                        <p>{d.registration_response}</p>
                                    </>
                                ),
                        date_created: moment(d.date_created).utc().format('YYYY-MM-DD HH:mm:ss'),
                        actions: (
                            <div>
                                <a
                                    className='btn btn-sm btn-round btn-outline-dark'
                                    href={`https://portalapi.kenyabankers.coop/api-internal/action/generate_pdf/${"kbs_application_form_"+d.msisdn+"_"+d.id_number}/${d.id}`}
                                >
                                    Generate Report
                                </a>
                                &nbsp;
                                <a
                                    className='btn btn-sm btn-round btn-outline-dark'
                                    href={"#"}
                                    onClick={() => {
                                        this.setState({moreDetailsModal: true, moreDetailsData: d});
                                    }}
                                >
                                    View Data
                                </a>
                            </div>

                        ),

                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default Registration;

import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';

class MerchantTransactionsProcessed extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='Processed Merchant Transactions'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Table
                            // search={['firstname', 'lastname']}
                            // sort="created_at"
                            // sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchMerchantTransactionsProcessed();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchMerchantTransactionsProcessed = () => {
        this.setState({table_loading: true});

        window.app
            .service('merchant-transaction-processed')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default MerchantTransactionsProcessed;

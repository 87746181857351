import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment-timezone';
import Filter from "../../components/filter";

class SMSList extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav name='All SMS'></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
                    <div className='p-4'>
                        <Filter
                            filter={[]}
                            dateRange={['startdate', 'enddate']}
                            dateColumn={'MessageTimestamp'}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchSMSList();
                                }, 0);
                            }}
                        />
                        <Table
                            search={['Destination', 'Message']}
                            sort="RecordID"
                            sortDirection={-1}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: {...this.state.query, ...params}});
                                setTimeout(() => {
                                    this.fetchSMSList();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                    </div>
                </div>
            </div>
        );
    }

    fetchSMSList = () => {
        this.setState({table_loading: true});

        let {query} = this.state;
        // delete query.$sort;

        window.app
            .service('dbcdr')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    return {
                        // RecordID: d.RecordID,
                        Originator: d.Originator,
                        Destination: d.Destination,
                        Message: d.Message,
                        // serviceId: d.serviceId,
                        time: moment(d.MessageTimeStamp).format('YYYY-MM-DD HH:mm:ss'),
                        // MessageDirection: d.MessageDirection,
                        // MessageIP: d.MessageIP,
                        // MessageQueue: d.MessageQueue,
                        // MessageID: d.MessageID,
                        // SMSCID: d.SMSCID,
                        // BinaryData: d.BinaryData,
                        // DCS: d.DCS,
                    };
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
}

export default SMSList;
